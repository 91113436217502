import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router";
import { Spacer } from "../../styles";
import Footer from "../footer/footer";
import { Nav } from "../nav/nav";

export default function MainLayout({ children }: { children: ReactElement }) {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Nav />
      {children}
      <Footer />
      <Spacer height={"20px"} heightSm={"20px"} />
    </>
  );
}
