import * as React from "react";
import { Component } from "react";
import { useNavigate } from "react-router";
import { ImageCardStyledProp } from "./interface";
import playIcon from "../assets/icons/playstore-icon.svg";
import appleIcon from "../assets/icons/apple-icon.svg";
import Aos from "aos";
import "aos/dist/aos.css";

import {
  HeroImgCardStyled,
  HeroTopStyled,
  IconTextStyled,
  SpacerStyled,
  TopNavLinkStyled,
  UnitCarouselImgStyled,
  UnitStepCardStyled,
  VerticalSpacerStyled,
} from "./styled";

interface HeroImgCardProp {
  heightCoefficient?: number;
  className?: string;
  img?: string;
}
interface TopNavLinkProp {
  to?: string | undefined;
  activeClassName?: string | undefined;
  className?: string | undefined;
  children?: string;
}
interface IconTextProp {
  bgColor?: string;
  img?: string;
  text?: string;
  className?: string;
}
interface SpacerProp {
  height?: string;
  heightSm?: string;
  className?: string;
}
interface VerticalSpacerProp {
  width?: string;
  widthSm?: string;
  className?: string;
}
export const HeroImgCard: React.FC<HeroImgCardProp> = ({
  heightCoefficient,
  img,
  className,
  ...restProps
}) => {
  return (
    <HeroImgCardStyled
      src={img}
      heightCoefficient={heightCoefficient}
      className={className}
      {...restProps}
    ></HeroImgCardStyled>
  );
};
export const IconText: React.FC<IconTextProp> = ({
  img,
  bgColor,
  className,
  text,
  ...restProps
}) => {
  return (
    <IconTextStyled bgColor={bgColor} className={className} {...restProps}>
      <div className="icon">
        <img src={img} />
      </div>
      <p>{text}</p>
    </IconTextStyled>
  );
};

export const Spacer: React.FC<SpacerProp> = ({
  heightSm,
  height,
  className,
  ...restProps
}) => {
  return (
    <SpacerStyled
      height={height}
      heightSm={heightSm}
      className={className}
      {...restProps}
    ></SpacerStyled>
  );
};

export const VerticalSpacer: React.FC<VerticalSpacerProp> = ({
  widthSm,
  width,
  className,
  ...restProps
}) => {
  return (
    <VerticalSpacerStyled
      width={width}
      widthSm={widthSm}
      className={className}
      {...restProps}
    ></VerticalSpacerStyled>
  );
};

export const HeroTop: React.FC<any> = ({ title, subtext }) => {
  React.useEffect(() => {
    Aos.init({ duration: 1500, mirror: true, once: false });
  }, []);
  return (
    <>
      <HeroTopStyled>
        <div className="map-bg-ctn container-fluid  p-0 py-3 pad-alt">
          <Spacer height="200px" heightSm="100px" />
          <div className="row m-0 justify-content-center">
            <div className="col-md-6">
              <h1 className="hero-header" data-aos="fade-up">
                {title}
              </h1>
              <h3
                className="hero-subtext"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                {subtext}
              </h3>
            </div>
          </div>
          <Spacer height="200px" heightSm="100px" />
        </div>
      </HeroTopStyled>
    </>
  );
};

export const UnitCarouselImg: React.FC<any> = ({ img, ...restProps }) => {
  return (
    <UnitCarouselImgStyled {...restProps}>
      <img className="img-fluid" src={img} />
    </UnitCarouselImgStyled>
  );
};
export const UnitStepCard: React.FC<any> = ({ title, text, ...restProps }) => {
  return (
    <UnitStepCardStyled {...restProps}>
      <h3 className="yellow-text">{title}</h3>{" "}
      <Spacer height={"1rem"} heightSm={"1rem"} />
      <p>{text}</p>
    </UnitStepCardStyled>
  );
};
