import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/homepage/homepage";
import Aboutpage from "./pages/aboutpage/aboutpage";
import SolutionPage from "./pages/solutionpage/solutionPage";
import Support from "./pages/Support/support";
import Faq from "./pages/Faq";
import Packages from "./pages/Packages/Packages";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import MainLayout from "./sections/MainLayout";
import TermsAndConditions from "./pages/termsandconditions";
import PrivacyPolicy from "./pages/privacypolicy";
import ServiceLevelAgreement from "./pages/servicelevelagreement";
import Agents from "./pages/agents";
import NewLoan from "./pages/new-loan";
import SearchResult from "./pages/search-loan/result";
import SearchLoan from "./pages/search-loan";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MainLayout>
                  <HomePage />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <MainLayout>
                  <Aboutpage />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <MainLayout>
                  <TermsAndConditions />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <MainLayout>
                  <PrivacyPolicy />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/service-level-agreement"
            element={
              <>
                <MainLayout>
                  <ServiceLevelAgreement />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/solutions"
            element={
              <>
                <MainLayout>
                  <SolutionPage />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/support"
            element={
              <>
                <MainLayout>
                  <Support />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <MainLayout>
                  <Faq />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/package"
            element={
              <>
                <MainLayout>
                  <Packages />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/search-loan"
            element={
              <>
                <MainLayout>
                  <SearchLoan />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/search-loan/result"
            element={
              <>
                <MainLayout>
                  <SearchResult />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/new-loan/:id"
            element={
              <>
                <MainLayout>
                  <NewLoan />
                </MainLayout>
              </>
            }
          />
          <Route
            path="/agents"
            element={
              <>
                <MainLayout>
                  <Agents />
                </MainLayout>
              </>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
