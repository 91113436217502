import { Spacer } from "../../styles";
import {
  Button,
  HeroCaption,
  InfoText,
  TopNavRoundedButton,
} from "../../styles/styled";
import Aos from "aos";
import "aos/dist/aos.css";
import "./style.scss";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { Icon } from "@iconify/react";

interface STATIC_FORM {
  [name: string]: string;
  company_name: string;
  email: string;
  message: string;
}

const STATIC_FORM: STATIC_FORM = {
  name: "",
  company_name: "",
  email: "",
  message: "",
};

const Support = () => {
  const [form, setForm] = useState({ ...STATIC_FORM });
  const [formErrors, setFormErrors] = useState({ ...STATIC_FORM });

  function validateForm(data: STATIC_FORM, inputkeys: string[]) {
    let keys = inputkeys?.length > 0 ? inputkeys : Object.keys(data);
    for (let key of keys) {
      if (validator?.isEmpty(data[key], { ignore_whitespace: true })) {
        setFormErrors((prev) => ({ ...prev, [key]: "Field is required" }));
      } else if (key === "email" && !validator.isEmail(data[key])) {
        setFormErrors((prev) => ({ ...prev, [key]: "Email is invalid" }));
      } else {
        setFormErrors((prev) => ({ ...prev, [key]: "" }));
      }
    }
  }

  function handleInput(e: any) {
    let val = e.target.value;
    let name = e.target.name;

    let data: STATIC_FORM = { ...form };

    data[name] = val;

    setForm((prev: any) => data);

    validateForm(data, [name]);
  }

  const recaptcha: any = useRef(null);

  const [sendLoading, setSendLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  async function sendEmail() {
    setSendLoading(true);
    try {
      const captchaToken = await recaptcha.current.executeAsync();
      recaptcha.current.reset();

      const { status, text } = await emailjs.send(
        "service_dl18svd",
        "template_28kdgpf",
        { ...form, "g-recaptcha-response": captchaToken },
        "CFxRBKdNQDYs7R0dx"
      );

      console.log(status, text);

      setSuccessMessage("Your message has been sent successfully!!");
      setErrMessage("");
      setForm({ ...STATIC_FORM });
      setFormErrors({ ...STATIC_FORM });
    } catch (err: any) {
      console.log(err);
      setErrMessage(
        err?.message ||
          err ||
          "We are unable to send your message at the moment"
      );
      setSuccessMessage("");
    }
    setSendLoading(false);
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <section className="w-100  pad-alt">
      <Spacer height={"50px"} heightSm={"70px"} />
      <HeroCaption className="fs-2 text-center" data-aos="fade-right">
        How can we help?
      </HeroCaption>
      <InfoText data-aos="fade-right">
        Tell us how we can help and our team will happily answer your <br />{" "}
        questions and give you feedback.
      </InfoText>
      <Spacer height={"50px"} heightSm={"70px"} />
      <div className="contact-us mx-auto card border-0 support-card ">
        <div className="row gy-3 px-3 px-lg-5 px-md-5 px-xxl-5">
          <div className="col-sm-12 col-md-12 col-md-12 px-4">
            <ReCAPTCHA
              ref={recaptcha}
              sitekey="6LcdLbAkAAAAAMgoogT0BwRZSUDgyXq9208Kr8rW"
              size="invisible"
            />
            <form
              className="w-full grid grid-cols-2 gap-6 py-[56px] px-[20px] md:px-[56px]"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="col-span-2">
                {successMessage && (
                  <div className="noti w-full p-4 bg-green-100 text-success rounded flex items-center">
                    <Icon
                      icon="mdi:check-circle-outline"
                      className="text-3xl inline-block mr-4"
                    />
                    {successMessage}
                  </div>
                )}
                {errMessage && (
                  <div className="noti w-full p-4 bg-red-100 text-error rounded flex items-center">
                    <Icon
                      icon="mdi:check-circle-outline"
                      className="text-3xl inline-block mr-4"
                    />
                    {errMessage}
                  </div>
                )}
              </div>
              <div className="one grid gap-6">
                <div className="input-ctn">
                  <input
                    type="text"
                    className="custom-input w-full m-0"
                    id="name"
                    name="name"
                    aria-describedby="emailHelp"
                    placeholder="Name"
                    data-aos="fade-up"
                    value={form.name}
                    onInput={handleInput}
                  />
                  {formErrors?.name && (
                    <div className="text-error text-xs">{formErrors.name}</div>
                  )}
                </div>
                <div className="input-ctn">
                  <input
                    type="text"
                    placeholder="Company name"
                    className="custom-input w-full m-0"
                    id="company_name"
                    name="company_name"
                    data-aos="fade-up"
                    value={form.company_name}
                    onInput={handleInput}
                  />
                  {formErrors?.company_name && (
                    <div className="text-error text-xs">
                      {formErrors.company_name}
                    </div>
                  )}
                </div>
                <div className="input-ctn">
                  <input
                    type="Email"
                    className="custom-input w-full m-0"
                    id="email"
                    name="email"
                    placeholder="Email"
                    aria-describedby="emailHelp"
                    data-aos="fade-up"
                    value={form.email}
                    onInput={handleInput}
                  />
                  {formErrors?.email && (
                    <div className="text-error text-xs">{formErrors.email}</div>
                  )}
                </div>
              </div>
              <div className="two h-full">
                <div className="h-full">
                  <textarea
                    placeholder="Leave your message here..."
                    className="custom-input w-full mx-0 h-full resize-none"
                    rows={8}
                    data-aos="fade-up"
                    id="message"
                    name="message"
                    value={form.message}
                    onInput={handleInput}
                  ></textarea>
                  {formErrors?.message && (
                    <div className="text-error text-xs">
                      {formErrors.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex col-span-2 w-100 justify-content-end m-0">
                <button
                  className="btn disabled:pointer-events-none disabled:opacity-[.65] outline-transparent focus:outline-transparent active:outline-transparent py-[16px] px-[20px] text-white text-sm text-center bg-primary rounded-[10px] whitespace-nowrap"
                  data-aos="fade-up"
                  disabled={
                    Object?.values(formErrors).join("").length > 0 ||
                    !form.name ||
                    !form.company_name ||
                    !form.email ||
                    !form.message
                  }
                  onClick={sendEmail}
                  type="button"
                >
                  {sendLoading ? (
                    <>
                      <Icon
                        icon="mdi:loading"
                        className="animate-spin text-2xl mr-3 inline-block"
                      />
                      <span>Sending</span>
                    </>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Spacer height={"50px"} heightSm={"20px"} />
      <InfoText>
        You can also call our customer service line between 9:00am - 5:00pm on
        +234 (0) 916 316 9787{" "}
      </InfoText>
    </section>
  );
};

export default Support;
