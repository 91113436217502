import { useContext } from "react";
import { NewLoanContext } from "..";
import Spacer from "../../../components/Spacer";
import { paymentFrequencyOptions } from "../../../utils/data/static";
import { getFormattedNumber, nairaSymbol } from "../../../utils/functions";
import Button from "../../../components/Button";

export default function SuggestPlanForm() {
  const { formData, formErrors, handleInput, step, setStep, formIsValid } =
    useContext(NewLoanContext);
  return (
    <section className="w-100 overflow-hidden pad-alt mt-[142px] mb-[104px]">
      <div className="w-full max-w-[953px] mx-auto">
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="w-full py-[63px] px-[35px] md:px-[154px] mb-[20px] border border-[#EBEBEB] rounded-[20px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)]"
        >
          <h4 className="text-[#1C1C1C] text-[28px] leading-[38.25px] font-semibold mb-[58px]">
            Suggest a payment plan
          </h4>
          <div className="w-full max-w-[379px] flex justify-between md:justify-start gap-6 md:gap-[145px] text-dark-600 text-base font-semibold">
            <h4>Outstanding Loan Amount</h4>
            <p className="whitespace-nowrap">
              {nairaSymbol}{" "}
              {getFormattedNumber(
                parseFloat(formData?.loan_amount?.toString() || "0"),
                2
              )}
            </p>
          </div>
          <Spacer size="36px" />
          <div className="form-area grid gap-[45px] mb-[49px]">
            <div className="input-ctn">
              <label
                htmlFor="frequency"
                className="text-dark-600 text-base font-semibold mb-[17px]"
              >
                How do you want to pay your loan?
              </label>
              <div className="grid gap-[13px]">
                {paymentFrequencyOptions.map((option) => (
                  <div className="input-ctn flex items-center gap-[16px]">
                    <input
                      type="checkbox"
                      name="frequency"
                      id={option.value}
                      className="switch"
                      checked={
                        formData?.frequency == option.value ? true : false
                      }
                      onChange={(e) =>
                        handleInput({
                          target: {
                            name: e?.target?.name,
                            value: option.value,
                          },
                        })
                      }
                    />
                    <label
                      htmlFor={option.value}
                      className="text-dark-700 text-base m-0"
                    >
                      {option.name}
                    </label>
                  </div>
                ))}
              </div>
              {formErrors?.frequency && (
                <p className="error-ctn">{formErrors.frequency}</p>
              )}
            </div>
            <div className="input-ctn">
              <label
                htmlFor="amount"
                className="text-dark-600 text-base font-semibold mb-[17px]"
              >
                How much amount would you like to be paying according to your
                selected frequency?
              </label>
              <input
                type="text"
                name="amount"
                id="amount"
                placeholder="N100,000"
                className={`w-full rounded ${
                  formErrors.amount && "input-error"
                }`}
                value={formData.amount}
                onInput={handleInput}
              />
              {formErrors?.amount && (
                <p className="error-ctn">{formErrors?.amount}</p>
              )}
            </div>
            <div className="input-ctn">
              <label
                htmlFor="discount"
                className="text-dark-600 text-base font-semibold mb-[17px]"
              >
                How much discount do you want on your loan?
              </label>
              <input
                type="text"
                name="discount"
                id="discount"
                placeholder="5%"
                className={`w-full rounded ${
                  formErrors.discount && "input-error"
                }`}
                value={formData.discount}
                onInput={handleInput}
              />
              {formErrors?.discount && (
                <p className="error-ctn">{formErrors?.discount}</p>
              )}
            </div>
            <div className="input-ctn">
              <label
                htmlFor="discount"
                className="text-dark-600 text-base font-semibold mb-[17px]"
              >
                Payment start date
              </label>
              <div className="input-ctn">
                <div className="input-ctn relative w-full">
                  <input
                    type="date"
                    name="payment_start_date"
                    id="payment_start_date"
                    className={`w-full rounded ${
                      formErrors.payment_start_date && "input-error"
                    }`}
                    value={formData.payment_start_date}
                    onInput={handleInput}
                  />
                  {!formData.payment_start_date && (
                    <div
                      className={`custom-input absolute border-transparent bg-white w-[70%] h-[80%] top-[5px] flex items-center left-[1px] pointer-events-none ${
                        !formData.payment_start_date && "text-placeholder"
                      }`}
                    >
                      DD/MM/YYYY
                    </div>
                  )}
                </div>
                {formErrors?.payment_start_date && (
                  <p className="error-ctn">{formErrors?.payment_start_date}</p>
                )}
              </div>
            </div>
          </div>
          <Button
            className="w-full mb-[33px] text-white p-[15px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
            disabled={!formIsValid}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </section>
  );
}
