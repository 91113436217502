import validator from "validator";

export const restructureFormErrorInit = {
  plan: "",
  loan_type: "",
  maximum_payment_period: "",
  file: "",
  rewards: "",
  campaign_channels: "",
  duration_of_campaign: "",
  number_of_borrowers: "",
  agree_to_terms: "",
};

export const restructureFormInit = {
  name: "",
  loan_type: "",
  maximum_payment_period: "",
  status: 1,
  plan: [],
  file: undefined,
  rewards: "",
  campaign_channels: [],
  duration_of_campaign: 0,
  number_of_borrowers: 0,
  batch_ref: "",
  analysis_summary: null,
  agree_to_terms: true,
};

export const formStepInit = {
  page1: true,
  page2: false,
  page3: false,
  page4: false,
  page5: false,
  page6: false,
  page7: false,
};

export const paymentFrequencyOptions = [
  { name: "Daily", value: "1" },
  { name: "Weekly", value: "7" },
  { name: "Bi-weekly", value: "14" },
  { name: "Monthly", value: "30" },
  { name: "Quarterly", value: "90" },
];

export const frequencyOptions = [
  { name: "Daily", value: "1" },
  { name: "Every 2 days", value: "2" },
  { name: "Every 3 days", value: "3" },
  { name: "Every 5 days", value: "5" },
  { name: "Weekly", value: "7" },
];

export const channelOptions = [
  { name: "Email", value: 1 },
  { name: "SMS", value: 2 },
  { name: "Whatsapp", value: 3 },
  { name: "Bot call", value: 4 },
];

export const channelStatusInit = {
  sms_channel_status: false,
  whatsapp_channel_status: false,
  email_channel_status: false,
  botcall_channel_status: false,
};

export const channels = ["email", "sms", "whatsapp", "botCall"];

export const CAMPAIGN_DURATIONS = [
  { label: "1 week", value: 1 },
  { label: "2 weeks", value: 2 },
  { label: "4 weeks", value: 4 },
  { label: "6 weeks", value: 6 },
  { label: "8 weeks", value: 8 },
  { label: "12 weeks", value: 12 },
];

export const lobbyFormInit = {
  id: 11,
  category: 3,
  label: "Daily Payment, X% off Outstanding Loan Amount",
  inputs: [
    {
      label: "Enter % discount on current delinquent amount",
      placeholder: "---Add %---",
      index: 1,
    },
  ],
  validator: (data: any) => {
    let valid = false;
    for (let val of data) {
      if (
        !val ||
        validator.isEmpty(val?.toString()) ||
        !validator.isNumeric(val?.toString())
      ) {
        valid = false;
      } else {
        valid = true;
      }
    }
    return valid;
  },
  data: [11, null],
  status: false,
};

export const allRestructurePlanCategoriesInit = [
  {
    id: 1,
    label: "Flexible Payment Frequency",
    show: false,
  },
  {
    id: 2,
    label: "Discounts Or Forfeiture Of Interest Amount",
    show: false,
  },
  {
    id: 3,
    label: "Discount On Outstanding Loan Amount",
    show: false,
  },
  {
    id: 4,
    label: "Tenure Extension - (Customer Sets Amount & Frequency Of Payment)",
    show: false,
  },
  {
    id: 5,
    label: "One-Off Payments",
    show: false,
  },
  {
    id: 6,
    label: "Discounted Flexible Instalmental Payments",
    show: false,
  },
  {
    id: 7,
    label: "Maxiflex - Customer Sets The Terms",
    show: false,
  },
];

export const allRestructurePlansInit = [
  {
    id: 1,
    category: 1,
    label: "Daily Payment of Outstanding Loan Amount",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 2,
    category: 1,
    label: "Weekly Payment of Outstanding Loan Amount",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 3,
    category: 1,
    label: "Monthly Payment of Outstanding Loan Amount",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 4,
    category: 1,
    label: "Quarterly Payment of Outstanding Loan Amount",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 5,
    category: 2,
    label: "Daily Payment of Outstanding Principal Only",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 6,
    category: 2,
    label: "Weekly Payment of Outstanding Principal Only",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 7,
    category: 2,
    label: "Bi-Weekly Payment of Outstanding Principal Only",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 8,
    category: 2,
    label: "Monthly Payment of Outstanding Principal Only",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 9,
    category: 2,
    label: "Quarterly Payment of Outstanding Principal Only",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 10,
    category: 2,
    label: "X% discount on Interest Rate or Interest Amount",
    inputs: [
      {
        label: "Enter % discount on accrued interest",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [10, null],
    status: false,
  },
  {
    id: 11,
    category: 3,
    label: "Daily Payment, X% off Outstanding Loan Amount",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [11, null],
    status: false,
  },
  {
    id: 12,
    category: 3,
    label: "Weekly Payment, X% off Outstanding Loan Amount",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [12, null],
    status: false,
  },
  {
    id: 13,
    category: 3,
    label: "Bi-Weekly Payment, X% off Outstanding Loan Amount",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [13, null],
    status: false,
  },
  {
    id: 14,
    category: 3,
    label: "Monthly Payment, X% off Outstanding Loan Amount",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [14, null],
    status: false,
  },
  {
    id: 15,
    category: 3,
    label: "Quarterly Payment, X% off Outstanding Loan Amount",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [15, null],
    status: false,
  },
  {
    id: 16,
    category: 4,
    label: "Extend loan tenure by X number of Days - (Oustanding Loan Amount)",
    inputs: [
      {
        label: "Enter number of days",
        placeholder: "---Add days---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [16, null],
    status: false,
  },
  {
    id: 17,
    category: 4,
    label: "Extend loan tenure by X number of Days - (Principal Amount Only)",
    inputs: [
      {
        label: "Enter number of days",
        placeholder: "---Add days---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [17, null],
    status: false,
  },
  {
    id: 18,
    category: 4,
    label: "Extend loan tenure by X number of weeks - (Oustanding Loan Amount)",
    inputs: [
      {
        label: "Enter number of weeks",
        placeholder: "---Add weeks---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [18, null],
    status: false,
  },
  {
    id: 19,
    category: 4,
    label: "Extend loan tenure by X number of weeks - (Principal Amount Only)",
    inputs: [
      {
        label: "Enter number of weeks",
        placeholder: "---Add weeks---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [19, null],
    status: false,
  },
  {
    id: 20,
    category: 4,
    label:
      "Extend loan tenure by X number of months - (Oustanding Loan Amount)",
    inputs: [
      {
        label: "Enter number of months",
        placeholder: "---Add months---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [20, null],
    status: false,
  },
  {
    id: 21,
    category: 4,
    label: "Extend loan tenure by X number of months - (Principal Amount Only)",
    inputs: [
      {
        label: "Enter number of months",
        placeholder: "---Add months---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [21, null],
    status: false,
  },
  {
    id: 22,
    category: 5,
    label: "One off Payment of Outstanding Principal Only with X% Discount",
    inputs: [
      {
        label: "Enter % discount on principal amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [22, null],
    status: false,
  },
  {
    id: 23,
    category: 5,
    label: "One off Payment of Outstanding Loan Amount with X% Discount",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [23, null],
    status: false,
  },
  {
    id: 24,
    category: 6,
    label:
      "X% off Outstanding Amount with balance paid in X number of installments",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
      {
        label: "Enter number of installments",
        placeholder: "---Add installments---",
        index: 2,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [24, null, null],
    status: false,
  },
  {
    id: 25,
    category: 6,
    label:
      "X% off Outstanding Principal with balance paid in X number of installments.",
    inputs: [
      {
        label: "Enter % discount on outstanding principal",
        placeholder: "---Add %---",
        index: 1,
      },
      {
        label: "Enter number of installments",
        placeholder: "---Add installments---",
        index: 2,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [25, null, null],
    status: false,
  },
  {
    id: 26,
    category: 6,
    label:
      "X% off on X% initial payment with balance paid in X number of installments.",
    inputs: [
      {
        label: "Enter % discount on current delinquent amount",
        placeholder: "---Add %---",
        index: 1,
      },
      {
        label: "Enter % of current delinquent amount to pay on first payment",
        placeholder: "---Add %---",
        index: 2,
      },
      {
        label: "Enter number of installments to pay balance",
        placeholder: "---Add installments---",
        index: 3,
      },
    ],
    validator: lobbyFormInit.validator,
    data: [26, null, null, null],
    status: false,
  },
  {
    id: 27,
    category: 7,
    label:
      "Customer Sets [Frequency - (Daily, Weekly, Monthly), Repayment Amount & Duration]",
    inputs: null,
    data: null,
    status: false,
  },
  {
    id: 28,
    category: 7,
    label: "Customer Sets number of Installments, Duration & Repayment Amount",
    inputs: null,
    data: null,
    status: false,
  },
];

export const initUnitCost = {
  sms_channel: 3,
  whatsapp_channel: 5,
  email_channel: 2,
  botcall_channel: 4,
};

export const restructureEstimatedCostInit = [];

export const unitChannelFormInit = {
  updated_at: "",
  channel_id: "",
  frequency: "",
  template_id: "",
};

export const restructureDisabledStatusInit = [
  { id: 1, inputs: ["file"], status: true },
  { id: 2, inputs: [], status: true },
  {
    id: 3,
    inputs: [
      "name",
      "loan_type",
      "number_of_borrowers",
      "duration_of_campaign",
    ],
    status: true,
  },
  { id: 4, inputs: ["campaign_channels"], status: true },
  { id: 5, inputs: ["plan", "maximum_payment_period"], status: true },
  { id: 6, inputs: ["rewards"], status: true },
  { id: 7, inputs: ["agree_to_terms"], status: true },
];
