import React, { Component } from "react";

import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer, UnitCarouselImg } from "../../styles";
import { HeroText, HeroTextLg } from "../../styles/styled";
import borrow_me_logo from "../../assets/svgs/borrow_me_logo.svg";
import fairmoney_logo from "../../assets/svgs/fairmoney_logo.svg";
import sterling_logo from "../../assets/svgs/sterling_logo.svg";
import kiakia_logo from "../../assets/svgs/kiakia_logo.svg";
import credpal_logo from "../../assets/svgs/credpal_logo.svg";
const AllBanks = [
  {
    src: borrow_me_logo,
  },
  {
    src: fairmoney_logo,
  },
  {
    src: sterling_logo,
  },

  {
    src: kiakia_logo,
  },
  {
    src: credpal_logo,
  },
];

export const CarouselSection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="carousel-section w-100 overflow-x-hidden pad-alt py-[40px] md:py-[80px]">
        <HeroTextLg
          className="w-full max-w-[480px] text-center mx-auto mb-[35px] md:mb-[66px]"
          data-aos="fade-up"
        >
          Join other lending companies using restructure
        </HeroTextLg>
        <div
          className="w-100 carousel_ctn d-flex align-items-center justify-content-around mt-3"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {AllBanks.map((bank) => {
            return <UnitCarouselImg img={bank.src} />;
          })}
        </div>
      </section>
    </>
  );
};
