import React from "react";
import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import logo from "../../assets/images/fotlogo.png";
import { Spacer } from "../../styles";
import { Link } from "react-router-dom";
import fb from "../../assets/svgs/fb.svg";
import twitter from "../../assets/svgs/twitter.svg";
import insta from "../../assets/svgs/instagram.svg";
import linkin from "../../assets/svgs/linkin.png";

const Footer = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="footer-section w-100 pad-alt m-0" id="footer">
        {" "}
        <div className="container-fluid  p-0 py-3">
          <Spacer height="70px" heightSm="30px" />
          <hr data-aos="fade-up" className="bg-[#EBEBEB] h-[1px]" />
          <div
            className="row m-0 justify-content-between gy-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="col-md-3 col-12 p-0">
              <Link to="/">
                <img className="img-fluid" src={logo} alt="logos" />
              </Link>
            </div>
            <div className="col-md-auto col-6 p-0">
              <h4 className="footer-title">Company</h4>
              <ul className="footerlist">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <a href="https://app.restructure.com.ng/createaccount/">
                    Create account
                  </a>
                </li>
                <li>
                  <Link to="/support">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-auto col-6 p-0">
              <h4 className="footer-title">Resources</h4>
              <ul className="footerlist">
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
                <li>
                  <a href="https://docs.restructure.com.ng/">Documentation</a>
                </li>
                <li>
                  <Link to="/support">Request demo</Link>
                </li>
                <li>
                  <a href="https://medium.com/@blacklistnigeria">Blog</a>
                </li>
              </ul>
            </div>
            <div className="col-md-auto col-6 p-0">
              <h4 className="footer-title">Legal</h4>
              <ul className="footerlist">
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/service-level-agreement">SLA</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-auto col-6 p-0">
              <h4 className="footer-title">Contact Us</h4>
              <ul className="footerlist">
                <li>
                  <Spacer height="20px" heightSm="12px" />
                  <div className="flex gap-4">
                    <a href="https://www.facebook.com/RestructureHq/">
                      <img
                        src={fb}
                        title="facebook"
                        className="img-fluid"
                        alt="files"
                      />
                    </a>
                    <a href="https://www.twitter.com/RestructureHq">
                      <img
                        src={twitter}
                        title="twitter"
                        className="img-fluid"
                        alt="twitter"
                      />
                    </a>
                    <a href="https://www.instagram.com/restructurehq/">
                      <img
                        src={insta}
                        title="instagram"
                        className="img-fluid"
                        alt="instagram"
                      />
                    </a>
                    <a href="https://www.linkedin.com/company/77873692/">
                      <img
                        src={linkin}
                        title="linkedin"
                        className="img-fluid"
                        alt="linkedin"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <a href="mailto:info@restructure.com.ng">
                    info@restructure.com.ng
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <Spacer height="50px" heightSm="50px" />
        </div>
      </section>
    </>
  );
};

export default Footer;
