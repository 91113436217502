import axios, { Axios, AxiosInterceptorManager, AxiosResponse } from "axios";

// export const baseURL = "https://restructure-api-app-mtjuz.ondigitalocean.app/api"; //Staging
export const baseURL = "https://prod-api.restructure.com.ng/api"; // Production ;
// export const baseURL = "https://restructure-api.blacklistng.com/public/api";

export const axiosInstance = new Axios({
  baseURL,
});

axiosInstance.interceptors.response.use(async (res) => {
  return {
    ...res,
    data:
      res?.config?.url?.includes("export_") ||
      res?.config?.url?.includes("download_borrowers")
        ? res?.data
        : typeof res?.data === "object"
        ? res?.data
        : JSON.parse(res?.data),
  };
});

axiosInstance.interceptors.request.use((req) => {
  console.log(req.data);
  return req;
});

export const getRequest = async (url: string) => {
  return await axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRequest = async (
  url: string,
  payload?: { [key: string]: any }
) => {
  return await axiosInstance.post(url, JSON.stringify(payload), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const multipartPostRequest = async (url: string, payload?: FormData) => {
  return await axiosInstance.postForm(url, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const login = async (payload: { [key: string]: string }) => {
  var headers = {
    "Content-Type": "application/json",
  };
  return await axiosInstance.post("/login", JSON.stringify(payload), {
    headers,
  });
};

export const logout = async () => {
  return await getRequest("/logout");
};

export const refreshToken = async () => {
  var headers = {
    "Content-Type": "application/json",
  };
  return await axiosInstance.post("/refresh_token", null, {
    headers,
  });
};

let timer: any;

export const initTokenRefresher = ({
  setAuthToken,
  setAuthUser,
  setAuthCompany,
  showErrorMessage,
}: {
  setAuthToken: (param: any) => void;
  setAuthUser: (param: any) => void;
  setAuthCompany: (param: any) => void;
  showErrorMessage: (param: any) => void;
}) => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(
    () =>
      startRefresh({
        setAuthToken,
        setAuthUser,
        setAuthCompany,
        showErrorMessage,
      }),
    1000 * 60 * 5
  );
};

export async function startRefresh({
  setAuthToken,
  setAuthUser,
  setAuthCompany,
  showErrorMessage,
}: {
  setAuthToken: (param: any) => void;
  setAuthUser: (param: any) => void;
  setAuthCompany: (param: any) => void;
  showErrorMessage: (param: any) => void;
}) {
  try {
    const {
      data: { status, ...rest },
    } = await refreshToken();

    if (status == "success") {
      setAuthToken(rest?.data?.token);
      initTokenRefresher({
        setAuthToken,
        setAuthUser,
        setAuthCompany,
        showErrorMessage,
      });
    } else {
      throw new Error(rest?.message || status);
    }
  } catch (err: any) {
    console.log(err);
    setAuthToken(null);
    setAuthUser(null);
    setAuthCompany(null);
    showErrorMessage(
      err?.response?.data?.message || err?.message || "Unknown error occured"
    );
    let timer: any = setTimeout(
      () => window.location.replace(window?.location?.host + "/login"),
      500
    );
    while (parseInt(timer) - 1) {
      clearTimeout(timer);
    }
  }
}
