import { Link } from "react-router-dom";
import success_gif from "../../../assets/images/message-sent-successfully-plane.gif";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
export default function LoanSuccess() {
  return (
    <section className="w-100 overflow-hidden pad-alt mt-[142px] mb-[104px]">
      <div className="w-full max-w-[374px] mx-auto">
        <img
          src={success_gif}
          alt="success gif"
          className="mx-auto w-[188px]"
        />
        <h4 className="text-[#021C4F] font-manrope text-2xl font-bold text-center">
          Request submitted successfully
        </h4>
        <Spacer size="18px" />
        <p className="px-4 text-center text-base fon-axiforma text-[#243656]">
          Relax! <br /> We will contact your lender to approve your request.
          Once that is done, we will reach out to you through sms. Thank you
        </p>
        <Spacer size="18px" />
        <Link to="/search-loan" className="w-fit block mx-auto">
          <Button className="w-fit text-white p-[15px] px-[50px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]">
            Okay
          </Button>
        </Link>
      </div>
    </section>
  );
}
