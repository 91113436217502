import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import {
  IconTextStyledProp,
  ImageCardStyledProp,
  SpacerStyledProp,
  VerticalSpacerStyledProp,
} from "./interface";
export const TopNavLinkStyled:any = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  padding: 5px;
  :hover {
    color: #3c0c4b;
  }
`;
export const TopNavLinkAltStyled:any = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  padding: 5px;
  :hover {
    color: #3c0c4b;
  }
`;
export const TopNavRoundedButton:any = styled.a`
  font-weight: 500;
  box-shadow: none !important;
  height: 52px;
  padding: 0 3.4rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #009ddf;
  border-radius: 10px;
  justify-content: center;
  color: #ffffff !important;
  font-size: 14px;
  text-decoration: none;
  max-width: 150px;
  white-space: nowrap;
  @media (max-width: 768px) {
    width: 100%;
    max-width: unset;
    font-size: 16px;
    height: 45px;
    border-radius: 8px;
    padding: unset;
  }
`;
export const SexyUnderline:any = styled.span`
  color: #009de0;
  position: relative;
  display: inline-block;
  :after {
    display: block;

    margin-top: -30px;
    content: "";
    z-index: -1;
    width: 104%;
    height: 1.875rem;
    background-color: #ccebfa;
    @media (max-width: 768px) {
      height: 1rem;
      margin-top: -15px;
    }
    animation-name: swell;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.32, 0.76, 0.16, 1);
    @keyframes swell {
      0% {
        width: 0%;
      }

      30% {
        width: 107%;
      }
      50% {
        width: 98%;
      }
      100% {
        width: 104%;
      }
    }
  }
`;

export const InfoText:any = styled.h5`
  font-size: 14px;
  line-height: 130%;

  text-align: center;
  font-weight: 600;
  color: #243656;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ThickerInfoText:any = styled.h5`
  font-size: 14px;
  line-height: 130%;
  font-family: var(--bold);

  color: #6a6a6a;
`;

export const HeroCaption:any = styled.h1`
  font-weight: 800;
  font-size: 56px;
  color: #000000;
  position: relative;
  font-family: var(--boldOnest);
  line-height: 129%;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 32px;
    text-align: center;
    line-height: 37px;
  }
`;
export const HeroText:any = styled.h5`
  font-weight: 400;
  font-size: 18px;
  color: #545454;
  max-width: 600px;
  line-height: 24px;
  @media (max-width: 1030px) {
    font-size: 20.9769px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    max-width: unset;
    line-height: 28px;
    text-align: center;
  }
`;
export const HeroImgCardStyled:any = styled.img`
  width: var(--imgWidthLg);
  height: calc(
    var(--imgWidthLg) *
      ${({ heightCoefficient = 1.19 }: any) =>
        heightCoefficient}
  );
  background: #a9a9a9;
  margin: 17px;
  border-radius: 16.1671px;
  @media (max-width: 1030px) {
    height: calc(
      var(--imgWidthMd) *
        ${({ heightCoefficient = 1.19 }: any) =>
          heightCoefficient}
    );
    margin: 22px;
    width: var(--imgWidthMd) px;
    border-radius: 21.9797px;
  }
  @media (max-width: 768px) {
    border-radius: 10.1637px;
    margin: 10px;
    height: calc(
      var(--imgWidthSm) *
        ${({ heightCoefficient = 1.19 }: any) =>
          heightCoefficient}
    );
    width: var(--imgWidthSm);
  }
`;
export const PurpleSectionTitle:any = styled.h2`
  font-weight: 700;
  font-size: 36px;
  color: #6a2381;
  font-family: var(--boldAxiforma);
  text-align: center;
  @media (max-width: 992px) {
    font-size: 34px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const SectionSubTitle:any = styled.h5`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  line-height: 30px;
  color: #303030;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 25px;
  }
`;
export const IconTextStyled:any = styled.div`
  margin: 10px;
  width: fit-content;
  .icon {
    background-color: ${({ bgColor = "#7B61FF1A" }: any) =>
      bgColor};
    display: flex;

    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 20px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  display: flex;
  align-items: center;
  p {
    font-weight: 700;
    margin: 0;
    font-size: 18px;
    color: #303030;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .icon {
      width: 90px;
      height: 90px;
      margin: 0;
    }
    p {
      font-size: 18px;

      font-weight: 700;
      margin-top: 20px;
    }
  }
`;
export const SpacerStyled:any = styled.div`
  height: ${({ height = "30px" }: any) => height};
  display: block;
  @media (max-width: 768px) {
    height: ${({ heightSm = "0px" }: any) => heightSm};
  }
`;
export const VerticalSpacerStyled:any = styled.div`
  width: ${({ width = "30px" }: any) => width};
  display: block;
  @media (max-width: 768px) {
    width: ${({ widthSm = "0px" }: any) => widthSm};
  }
`;
export const DarkHeaderText:any = styled.h3`
  font-weight: 700;
  font-size: 40px;
  color: #545454;
  text-align: center;
  font-family: var(--boldAxiforma);
  @media (max-width: 1030px) {
    font-size: 32.4167px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
export const DarkHeaderTextMd:any = styled.h3`
  font-weight: 700;
  font-size: 32px;
  color: #545454;
  text-align: center;
  margin: 0;
  font-family: var(--boldAxiforma);
  @media (max-width: 1030px) {
    font-size: 29px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const IntricateVerticalSpacer:any = styled.div`
  width: ${({ width = "30px" }: any) => width};
  display: block;
  @media (max-width: 992px) {
    width: ${({ widthMd = "0px" }: any) => widthMd};
  }
  @media (max-width: 768px) {
    width: ${({ widthSm = "0px" }: any) => widthSm};
  }
`;

export const HeroTopStyled:any = styled.section`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .map-bg-ctn {
    background-size: 90%;
    background-repeat: no-repeat;

    background-position: center;
  }
  .hero-header {
    font-size: 54px;
    text-align: center;
    text-transform: capitalize;

    color: #6a2381;
    font-weight: 700;
    font-family: var(--boldAxiforma);
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
  .hero-subtext {
    font-size: 23px;
    color: #545454;
    text-align: center;
    line-height: 30px;

    font-size: 23.04px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
export const ErrorText:any = styled.p`
  color: #df3515;
  padding-left: 15px;
  margin-bottom: 0px;
  font-size: 13px;
`;
export const P14:any = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #303030;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    text-align: center;
  }
`;

export const Button:any = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 52px;
  border: none;
  max-width: ${({ widthMax = "unset" }: any) => widthMax} !important;
  color: #fff !important;
  font-size: 16px;
  @media (max-width: 768px) {
    width: 125px;
    height: 58px;
  }
  border-radius: 7px;
  box-shadow: none !important;
  outline: none !important;
  background: #116cfd !important;
  :hover {
    background: #0f55c6 !important;
  }
`;

export const TabButton:any = styled.button`
  border: none;

  white-space: nowrap;
  font-family: var(--medium);

  background-color: transparent;
  box-shadow: none;
  color: #243656;
  font-size: 14px;

  margin-right: 50px;
  cursor: pointer;
  text-align: left;
  transition: 0.4s ease;
  :hover {
    color: #5a4706;
  }
`;

export const SpanList:any = styled.span`
  padding-left: 20px;
  padding-right: 15px;
  background-repeat: no-repeat !important;
  background-position-x: left;
  background-position-y: center;
`;
export const ButtonBland:any = styled.button`
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  background: #6a2381;
  transition: 0.2s ease;
  box-shadow: 0px 67px 80px rgba(55, 52, 169, 0.07),
    0px 43.4259px 46.8519px rgba(55, 52, 169, 0.0531481),
    0px 25.8074px 25.4815px rgba(55, 52, 169, 0.0425185),
    0px 13.4px 13px rgba(55, 52, 169, 0.035),
    0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815),
    0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519);
  border-radius: 7px;
  border: none;
  width: 240px;

  padding: 15px 40px;
  :hover {
    background: #5a216d;
  }
`;
export const UnitCarouselImgStyled:any = styled.div`
position:relative;
margin-right:30px;
:last-child{
  margin-right:0px;
}
align-items-center;
img{
  display:block;
}
`;
export const HeroTextLg:any = styled.h5`
  font-weight: 400;
  font-size: 22px;
  color: #545454;
  max-width: 600px;
  line-height: 30px;
  font-family: var(--mediumAxiforma);
  @media (max-width: 1030px) {
    font-size: 20.9769px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    font-size: 17px;
    max-width: unset;
    line-height: 28px;
    text-align: center;
  }
`;
export const UnitStepCardStyled:any = styled.div`
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 15px;
  padding-bottom: 30px;
  background: #2b2a2a43;
  h3 {
    margin: 0;
    font-size: 0.937rem;
    font-weight: 700;
  }
  p {
    line-height: 28px;
    color: #fff;
    font-size: 0.937rem;
    margin: 0;
  }
`;
