import { add, format } from "date-fns";
import { allRestructurePlansInit } from "../data/static";

export const getFormattedTransactionDate = (date: string | Date) => {
  if (!date) return "";
  return (
    format(new Date(date), "dd/mm/yy ") +
    format(new Date(date), "p").replace(" ", "").toLocaleLowerCase()
  );
};

export const getFormattedBroadcastHistoryDateTime = (date: string | Date) => {
  if (!date) return "";
  return (
    format(new Date(date), "p").replace(" ", "").toLocaleLowerCase() +
    format(new Date(date), " dd MMM yyyy")
  );
};

export const getFormattedCampaignInvoiceDate = (date?: string | Date) => {
  return format(date ? new Date(date) : new Date(), "MMM d, yyyy");
};

export const getFormattedNumber = (number: number, fraction: number) => {
  return new Intl.NumberFormat("en-NG", {
    minimumFractionDigits: fraction || 0,
    maximumFractionDigits: fraction || 0,
  }).format(number);
};

export const parseCustomPlansLabelData = (label: string, data: any[]) => {
  if (!label) return "";

  let key = "X";
  let indexes = [];

  for (let i = 0; i < label.length; i++) {
    if (label[i] === key) {
      indexes.push(i);
    }
  }

  indexes.forEach((val, idx) => {
    label = replaceChar(label, data[idx + 1], val);
  });

  return label?.replaceAll(" number of", "");
};

export function replaceChar(
  origString: string,
  replaceChar: string,
  index: number
) {
  let firstPart = origString.substring(0, index);
  let lastPart = origString.substring(index + 1);

  let newString = firstPart + replaceChar + lastPart;
  return newString;
}

export function getCustomPlanLabel(data: any[]) {
  if (!data) return "";
  let plan = allRestructurePlansInit.find((d) => d?.id === data[0]);
  return parseCustomPlansLabelData(plan?.label || "", data);
}

export const nairaSymbol = "₦";

export function encodeFile(file: File) {
  return new Promise((res, rej) => {
    try {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        res(e?.target?.result);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      rej(err);
    }
  });
}

export function decodeFile(file: { name: string; type: string; data: string }) {
  return new Promise((res, rej) => {
    try {
      let result = new File([file.data], file.name, { type: file.type });
      res(result);
    } catch (err) {
      rej(err);
    }
  });
}

export function delay(timeout: number) {
  return new Promise((res, rej) => {
    setTimeout(res, timeout);
  });
}

export function getPaymentEndDate(data: any) {
  if (!data) return "";
  return add(new Date(data?.payment_start_date || new Date().toISOString()), {
    days:
      parseInt(data?.frequency) *
        (Math.ceil(
          parseFloat(data?.loan_amount || "0") / parseFloat(data?.amount || "0")
        ) -
      1),
  });
}
