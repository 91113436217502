import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import hand from "../../assets/images/handshake.png";
import about from "../../assets/images/about_banner.png";
import { Spacer } from "../../styles";
import { HeroCaption, HeroText } from "../../styles/styled";

const Aboutus = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="about-hero-section w-100 pad-alt">
        <div className="container-fluid  p-0 pt-5 ">
          <Spacer height="00px" heightSm="0px" />
          <div className="top-hero position-relative">
            <div className="row w-100 p-0 m-0 position-relative">
              <div
                className="col-md-6 "
                data-aos="fade-right"
              >
                <HeroCaption className="mt-5">
                  Recover <br /> your{" "}
                  <span className="blue-text">
                    debts{" "}
                  </span>{" "}
                  the <br />{" "}
                  <span className="yellow-text">
                    smart way
                  </span>
                </HeroCaption>
                <HeroText>
                  Helping you manage and reduce your NPL
                  with smart, scalable,customizable tools
                </HeroText>

                <Spacer height={"50px"} heightSm={"20px"} />
              </div>
              <div
                className="col-md-6 p-0"
                data-aos="zoom-in"
                data-aos-delay="500"
              >
                <img
                  src={about}
                  className="img-fluid img-banner"
                  alt="banner"
                />
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="w-100">
          <div className="row w-100 p-0 m-0 position-relative">
            <div
              className="col-md-6  mt-4"
              data-aos="fade-right"
            >
              <img
                src={hand}
                className="img-fluid banner"
                alt="banner"
              />
              <Spacer height={"50px"} heightSm={"20px"} />
            </div>
            <div className="col-md-6 p-0 ">
              <Spacer height={"50px"} heightSm={"20px"} />
              <HeroCaption className="fs-2">
                We are a Mission Driven <br /> Company
              </HeroCaption>
              <p>
                Restructure is designed to enable lenders
                collect their debt by helping as many people
                as possible become debt free via flexible
                customization repayment option tied to
                rewards
              </p>
              <br /> <br />
              <p>
                Restructure is in strategic partnership with
                CRC Credit Bureau and FirstCentral Credit
                Bureau, this offers a robust and dynamic
                credit report database built to help lending
                companies gain wholesome insight into
                prospective borrowers’ willingness and
                capability to repay loans, through access to
                their past and active credit information.
              </p>
              {/* <Spacer
                height={"0px"}
                heightSm={"30px"}
              /> */}
            </div>{" "}
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
