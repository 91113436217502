import { Spacer } from "../../styles";
import "./styles.scss";
export default function PrivacyPolicy() {
  return (
    <div className="privacy-page">
      <Spacer height="80px" heightSm="50px" />
      <section className="pad-alt">
        <h1>Privacy Policy</h1>
        <div>
          <p>
            This Data Protection Policy in conjunction with our Privacy Policy
            (https://restructure.com.ng/Privacypolicy) expressed Restructure
            which hereinafter may be referred to as (“our” “we” or “the
            Company”) assurance and commitment to treating the information of
            customers, stakeholders, partners, and other interested parties
            (“You or Your”) with the utmost care and confidentiality by the
            Nigeria Data Protection Regulations and each jurisdiction which data
            subject who we process data.
          </p>
          <p>
            With the provisions of this data protection policy and our privacy
            policy, we acknowledge that we gather, store, and handle data
            fairly, transparently, and with utmost duty of care and respect
            towards individual rights.
          </p>
          <h6>1.0 Scope</h6>
          <p>
            This document is prepared in accordance with the provisions of the
            EU General Data Protection Regulation (GDPR) and the Nigerian Data
            Protection Regulation (NDPR). It sets out how Restructure applies
            and complies with the principles of the regulations in processing
            the personal data of individuals, clients, vendors, and even third
            parties that interact with Restructure.
          </p>
          <p>
            For personal data of individuals, this document also highlights
            their rights and covers the data subject(s) whose personal data is
            collected and processed in compliance with the GDPR/NDPR.
          </p>
          <p>
            As part of Restructure ’s operation, we often obtain and process
            information which includes names, addresses, phone numbers, Emails,
            bank verification number, national identity number, loan/credit
            history, bank account credit and debit data. We collect this
            information securely and transparently and only with your full
            consent/cooperation and knowledge with an aim to enable assess and
            determine your credit worthiness.
          </p>
          <p>
            To ensure the protection of this information, we apply the following
            rules.
          </p>
          <ul>
            <li>Use for lawful purpose only</li>
            <li>Only share with the third party to provide services</li>
            <li>Protect against any unauthorized and illegal access</li>
          </ul>
          <p>
            To exercise the provisions of data protection regulations and show
            our commitment to ensure our compliant and adhere to the provisions,
            We:
          </p>
          <ul>
            <li>Restrict and monitor access to sensitive data</li>
            <li>Develop transparent data collection procedures</li>
            <li>Train employees in online privacy and security measures</li>
            <li>
              Build secure networks to protect online data from cyberattacks
            </li>
            <li>
              Establish clear procedures for reporting privacy breaches or data
              misuse
            </li>
            <li>
              Establish data protection practices (document shredding, secure
              locks, data encryption, frequent backups, access authorization,
              etc.)
            </li>
          </ul>
          <h6>2.0 How We Obtain Your Personal Data</h6>
          <p>2.1 Roles and responsibilities:</p>
          <h6>Data Protection Officer</h6>
          <ul>
            <li>
              To ensure that this notice is correct and up-to-date, and bring
              this notice to the awareness of data subjects prior to the
              collection and processing of personal data by the Restructure,
              including on Restructure’s website https://restructure.com.ng
            </li>
          </ul>
          <p>All Employees/Staff who interact with personal data</p>
          <ul>
            <li>To follow the provisions of this policy document.</li>
            <li>
              To seek the consent of data subject(s) for the secure processing
              of their personal data.
            </li>
            <li>
              To ensure that this notice is brought to the attention of the data
              subject(s).
            </li>
          </ul>
          <p>
            We obtain your personal data when you use our service through the
            registration process. In the process of signing up, we request data
            such as name, phone number, address, Email, Company name,
            Designation, and Country. Through a third-party source, in the
            process of funding your wallet, you will be directed to our payment
            institution platform where you will be required to input your card
            details for such funding. Please note that the data at this stage is
            not stored on the Restructure platform but the third party.
            Likewise, while using our services, you may be required to fill in
            your details and/or your customer’s details during the use of our
            Phone book, Teams, Group, or Direct SMS services. In addition, when
            interacting with our customer service, you may be required to
            provide us with more personal data to provide quality services to
            you.
          </p>
          <h6>3.0 Policy Statement</h6>
          <p>
            Ordinary personal data may include personal identification details
            such as name and address, customer relationships, personal finances,
            tax-related matters, debts, work-related circumstances, family
            circumstances, residence, car, qualifications, applications, CV,
            date of employment, position, area of work, work phone, key data:
            name, address, date of birth, IP address or other similar
            non-sensitive information.
          </p>
          <h6>Information about national identification numbers</h6>
          <p>
            Identification and verification numbers as the BVN, NIN and related
            are used to validate the trueness and liveness of individuals being
            assessed for credit worthiness. This can be from the financial
            institution, credit organization, and government agencies.
          </p>
          <h6>Cookies</h6>
          <p>
            We use cookies to improve your browsing experience on our website.
            By browsing on our website, you consent to our use of cookies in
            accordance with our privacy/cookie policy.
          </p>
          <p>
            Ways in which we used the personal data provided to us include
            processing or delivering our services, notifying you of the status
            of the services, sending marketing or campaign affiliates to which
            you opt-in.
          </p>
          <h6>4.0 Why do we need the data?</h6>
          <p>
            Restructure ensures that the personal data collected and processed
            is necessary for the purpose of collection, and Restructure shall
            not collect or process more data than is reasonably required for a
            particular processing activity. In addition, every processing
            purpose has at least one lawful basis for processing to safeguard
            the rights of the data subjects, as listed below:
          </p>
          <p>Purpose of processing</p>
          <p>Lawful basis of processing</p>
          <h6>Identity verification and maintenance of records</h6>
          <p>
            Compliance with a legal obligation in which Restructure is subject
            to (KYC/KYB).
          </p>
          <h6>Financial transactional analytics</h6>
          <p>
            Required by a credit service organization to assess the credit
            worthiness of an applying borrower.
          </p>
          <h6>Credit history assessment</h6>
          <p>
            Required by a credit service organization to assess the credit
            worthiness of an applying borrower.
          </p>
          <p>
            Where Legitimate Interest is considered the legal basis for
            processing personal data, Restructure shall follow the steps below
            in carrying out a Legitimate Interest Assessment.
          </p>
          <p>1. Determine the Purpose for Processing</p>
          <p>
            In carrying out the purpose test, Restructure must establish the
            exact reason for the processing and how it benefits the
            organization. Answers to the following shall be provided to
            determine the exact purpose for processing:
          </p>
          <ul>
            <li>Description of the processing objective</li>
            <li>
              The likelihood of meeting the objective and how to determine if
              the objective was met
            </li>
            <li>
              The benefit of the processing and the significance to the
              organization
            </li>
            <li>
              Description of the possible impact of not processing and any other
              issues that might be relevant
            </li>
          </ul>
          <p>2. Determine the Necessity of the Processing</p>
          <p>
            Restructure must establish why the processing must take place, how
            the processing relates to the expected benefits, and any other
            alternatives and why they were not considered.
          </p>
          <p>
            3. Balance the identified interest with the Privacy Interest of the
            Data Subjects
          </p>
          <p>
            The following questions will be addressed under the balance test:
          </p>
          <ul>
            <li>Who are the data subjects (category)?</li>
            <li>
              What is the relationship between Restructure and the data subject?
            </li>
            <li>What personal data is to be processed?</li>
            <li>How will the processing impact the data subject?</li>
            <li>How will the data subject react to the processing?</li>
          </ul>
          <p>
            Restructure records this information in line with this policy, data
            protection impact assessment, and data inventory.
          </p>
          <h6>5.0 Sharing Your Personal Data</h6>
          <p>
            We may share your data with our partners or suppliers to provide
            services to you. Such data may include your name, phone number,
            email address, and other general related data. We may also share
            your data with companies or organizations that we engage to provide
            services to us for the furtherance of your transaction or direct you
            to a third-party website that may provide services for you directly
            and/or on our behalf. An example is the funding of your wallet
            stage.
          </p>
          <p>
            THE PRIVACY POLICIES OF THESE THIRD-PARTY SITES MAY BE DIFFERENT
            FROM OUR DATA PROTECTION AND PRIVACY POLICY. THUS, WILL GOVERN ANY
            TRANSACTIONS AND SERVICES PERFORMED ON THAT SITE. RESTRUCTURE AND
            SUCH COMPANIES ARE EACH INDEPENDENT DATA CONTROLLERS AND NOT JOINT
            DATA CONTROLLERS. Hence, you are advised to read and consent to such
            third-party site data protection and privacy policy.
          </p>
          <p>
            Notwithstanding the provision of this clause, Restructure does not
            sell or trade customer personal data to anyone. We only use it to
            render our services to you.
          </p>
          <h6>6.0 Consent</h6>
          <p>
            Restructure requires your explicit consent to process collected
            personal data. And by consenting to this privacy policy, you are
            giving us the permission to use/process your personal data
            specifically for the purpose identified before collection.
          </p>
          <p>
            If, for any reason, Restructure is requesting sensitive personal
            data from you, you will be rightly notified why and how the
            information will be used.
          </p>
          <p>
            You may withdraw consent at any time by requesting for Withdrawal of
            Consent form, following the Restructure Withdrawal of Consent
            Procedure.
          </p>
          <h6>7.0 Means of Securing Customer Personal Data</h6>
          <p>
            We secure your data by using a database with limited access and a
            secure server that encrypts all the data we collect. Hence
            protecting it from unauthorized third parties. We as well use
            multiple security control systems in layers to prevent access to
            information on our system.
          </p>
          <h6>7.1 Disclosure</h6>
          <p>
            Restructure will not pass on your personal data to third parties
            without first obtaining your consent.
          </p>
          <p>
            Where there is a need for a third party to process the personal data
            of data subjects, Restructure will enter into a Data Processing
            Agreement with the third party and be satisfied that the third party
            has adequate measures in place to protect the data against
            accidental or unauthorized access, use, disclosure, loss, or
            destruction.
          </p>
          <p>
            In a case where the disclosure is to third parties outside the
            jurisdiction of the GDPR and NDPR, Restructure will ensure that the
            third party meets the core regulatory standards prior to the
            transfer. This may include transferring the personal data to the
            third party where Restructure has satisfied that:
          </p>
          <ul>
            <li>
              The country of the recipient has adequate data protection controls
              established by legal or self-regulatory regime
            </li>
            <li>
              Restructure has a contract in place that uses existing or approved
              data protection clauses to ensure adequate protection
            </li>
            <li>
              Restructure is making the transfer under approved binding
              corporate rules
            </li>
            <li>
              Restructure is relying on approved codes of conduct or
              certification mechanisms, together with binding and enforceable
              commitments in the foreign country or international organization
              to apply the appropriate safeguards in relation to data subject
              rights
            </li>
            <li>
              Provisions inserted into administrative arrangements between
              public authorities or bodies authorized by the competent
              supervisory authority.
            </li>
          </ul>
          <h6>7.2 Retention of Records</h6>
          <p>
            In compliance with the GDPR/NDPR data retention policy, Restructure
            will process your personal data for about six (6) years and will
            retain the personal data for ten (10) years after processing.
          </p>
          <p>
            This retention period has been established to enable us to use the
            personal data for the necessary legitimate purposes identified, in
            full compliance with the legal and regulatory requirements. When we
            no longer need to use your personal information, we will delete it
            from our systems and records, and/or take steps to encrypt/anonymize
            it to protect your identity.
          </p>
          <h6>8.0 Customers Compliance</h6>
          <p>
            It is the responsibility of you as Restructure customer to ensure
            that you comply and continue to comply with Applicable Data
            Protection Laws and Regulation in your use of our services and in
            processing personal data. In addition, you will be responsible for
            the right to transfer, share and receive the consent of data
            subjects that you share with us to provide our service or usage of
            our service.
          </p>
          <p>
            By using our service, you hereby undersigned and agree as follow:
          </p>
          <p>
            You are complying and will continue to comply with applicable Data
            Protection Laws and Regulations that you processed through your use
            of our service.
          </p>
          <p>
            Hereby indemnify Restructure of any and/all liabilities, claims,
            legal actions, losses, and damages because of data violation and/or
            you are breaching any data protection laws and regulations.
          </p>
          <p>
            You will not intentionally collect or process any special categories
            of Personal Data unless Restructure includes such types of data in
            the content submitted to you
          </p>
          <p>
            Taking into account the nature of processing and the information
            available to you, You shall provide Restructure, insofar as this is
            possible and at Restructure’s written request, with all information
            required for Restructure to comply with statutory obligations under
            Applicable Data Protection Law (in particular, the obligations
            necessary to ensure the Restructure’s compliance with security of
            processing, personal data breach notification, data protection
            impact assessment, and prior consultations with supervisory
            authorities), and will assist Restructure, insofar as reasonable, in
            meeting these statutory obligations.
          </p>
          <h6>9.0 Customers Choices and Rights</h6>
          <h6>9.1 Data Subject Rights</h6>
          <p>
            Data subjects, according to the provision of the GDPR/NDPR, have
            certain rights. At any point while Restructure are in possession of
            or processing your personal data, you, the data subject, have the
            right to:
          </p>
          <ul>
            <li>Request a copy of the information that we hold about you</li>
            <li>
              Correct the data that we hold about you that is inaccurate or
              incomplete
            </li>
            <li>
              Ask for the data we hold about you to be erased from our
              systems/record
            </li>
            <li>
              Restrict processing of your personal data where certain conditions
              apply
            </li>
            <li>
              Have the data we hold about you transferred to another
              organization
            </li>
            <li>Object to certain types of processing like direct marketing</li>
            <li>
              Object to automated processing like profiling, as well as the
              right to be subject to the legal effects of automated processing
              or profiling
            </li>
            <li>
              Judicial review. If Restructure refuses your request under rights
              of access, we will provide you with a reason as to why. And you
              have the right to complain as outlined in clause 3.6 below.
            </li>
          </ul>
          <p>
            All the above requests will be forwarded should there be a third
            party involved in the processing of your personal data.
          </p>
          <p>
            You have the reserved right to decline the request of providing your
            personal information when requested by Restructure or Restructure’s
            authorized representatives. You also have the right to withdraw
            given consent to our data protection policy and privacy policy.
            Please contact us at dataprotection@restructure.com.ng if you wish
            to exercise any of your rights. It is however noteworthy to mention
            that certain services or all services may be unavailable to you
            afterward.
          </p>
        </div>
      </section>
      <Spacer height="80px" heightSm="50px" />
    </div>
  );
}
