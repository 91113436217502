import * as React from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import "./style.scss";
import Spacer from "../../components/Spacer";
import { CarouselSection } from "../../sections/carouselsection/carouselsection";
import { ExposaySection } from "../../sections/exposection/exposaysection";
import { StepSection } from "../../sections/stepsection/stepsection";
import Newsletter from "../../sections/newsletter/newsletter";
import home_dash from "../../assets/svgs/home_dash.svg";
import home_dash_2 from "../../assets/svgs/home_dash_2.svg";
import { HeroCaption } from "../../styles/styled";
import { SexyUnderline } from "../../styles/styled";
import { HeroText } from "../../styles/styled";
import { TopNavRoundedButton } from "../../styles/styled";
import repay_graphic from "../../assets/svgs/repay_graphic.svg";
import tick from "../../assets/svgs/tick.svg";
import agent_call_image2 from "../../assets/svgs/agent_call_image2.svg";
import { Link } from "react-router-dom";

export const HomePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <main className="homepage">
        <section className="hero flex flex-wrap lg:flex-nowrap gap-6 w-100 overflow-hidden pad-alt pr-0 mt-[117px]">
          <div
            className="left w-full lg:w-[calc(50%-12px)] pr-[1.5rem] sm:pr-[3rem] md:pr-[6rem] lg:p-0"
            data-aos="fade-right"
          >
            <div className="hidden lg:block">
              <Spacer size="67px" />
            </div>
            <HeroCaption>
              A.I powered <br />
              <SexyUnderline className="blue-text me-2">
                {" "}
                Debt Recovery
              </SexyUnderline>{" "}
              <br /> has never been easier
            </HeroCaption>
            <div className="md:hidden">
              <Spacer size="30px" />
            </div>
            <HeroText className="mx-auto md:mx-0">
              Meet the platform that helps you automate your debt recovery
              process by empowering you with the tools you need to create
              Flexible repayment options for your customers.
            </HeroText>{" "}
            <div className="hidden md:block">
              <Spacer size="50px" />
            </div>
            <div className="md:hidden">
              <Spacer size="20px" />
            </div>
            <div className="d-md-flex align-items-center justify-content-md-start justify-content-lg-start">
              <TopNavRoundedButton
                href="https://app.restructure.com.ng/createaccount/"
                className="btn boxer mx-auto md:mx-0"
              >
                Get Started
              </TopNavRoundedButton>
            </div>
            <div className="hidden md:block">
              <Spacer size="90px" />
            </div>
            <div className="md:hidden">
              <Spacer size="30px" />
            </div>
          </div>
          <div
            className="right h-auto min-h-[476.43px] relative w-full lg:w-[calc(50%-12px)] max-w-[671.41]"
            data-aos="fade-left"
          >
            <img
              src={home_dash}
              alt="home dash one"
              className="absolute top-0 right-0 w-full max-w-[350px] md:max-w-[430px]"
            />
            <img
              src={home_dash_2}
              alt="home dash two"
              className="z-10 absolute top-[calc(350px/3*1.3)] right-[calc(350px/3*1.5)] md:top-[calc(430px/3*1.3)] md:right-[calc(430px/3*1.5)] w-full max-w-[350px] md:max-w-[430px]"
            />
          </div>
        </section>
        <div className="hidden md:block">
          <Spacer size="140px" />
        </div>
        <div className="md:hidden">
          <Spacer size="80px" />
        </div>
        <CarouselSection />
        <div className="hidden md:block">
          <Spacer size="155px" />
        </div>
        <div className="md:hidden">
          <Spacer size="80px" />
        </div>
        <ExposaySection />
        <div className="hidden md:block">
          <Spacer size="110px" />
        </div>
        <div className="md:hidden">
          <Spacer size="55px" />
        </div>
        <section className="hero flex flex-wrap lg:flex-nowrap gap-6 w-100 overflow-hidden pad-alt py-[45px] md:py-[88px] bg-[#F9FDFE]">
          <div className="grid md:grid-cols-2 gap-y-10 gap-x-6">
            <div className="right">
              <h3
                className="text-[45px] font-bold leading-[52.8px] text-[#009DDF]"
                data-aos="fade-right"
              >
                Repay your loan,{" "}
                <span className="text-[#FF9E00]">your way</span>
              </h3>
              <Spacer size="15px" />
              <p className="w-full max-w-[578px] text-2xl leading-[35.3px] text-dark-600 font-medium">
                Renegotiate the terms of your loan and start making payment with
                ease.
              </p>
              <Spacer size="27px" />
              <ul className="options list-none p-0 grid gap-[25px] text-base">
                <li className="flex gap-[31px]" data-aos="fade-left">
                  <img
                    src={tick}
                    alt="tick"
                    className="w-full max-w-[30px] h-[26px]"
                  />
                  <p>
                    See all your loans from different lenders on a single
                    Dashboard.
                  </p>
                </li>
                <li className="flex gap-[31px]" data-aos="fade-left">
                  <img
                    src={tick}
                    alt="tick"
                    className="w-full max-w-[30px] h-[26px]"
                  />
                  <p>
                    Propose to your lender to give you flexible payment options
                    that fits your current situation, If the
                  </p>
                </li>
                <li className="flex gap-[31px]" data-aos="fade-left">
                  <img
                    src={tick}
                    alt="tick"
                    className="w-full max-w-[30px] h-[26px]"
                  />
                  <p>Lender agrees to your proposal, you start payment.</p>
                </li>
                <li className="flex gap-[31px]" data-aos="fade-left">
                  <img
                    src={tick}
                    alt="tick"
                    className="w-full max-w-[30px] h-[26px]"
                  />
                  <p>
                    Get access to a real-time credit score on your Dashboard.
                  </p>
                </li>
                <li className="flex gap-[31px]" data-aos="fade-left">
                  <img
                    src={tick}
                    alt="tick"
                    className="w-full max-w-[30px] h-[26px]"
                  />
                  <p>Clear your loan and improve your credit score.</p>
                </li>
                <li className="flex gap-[31px]" data-aos="fade-left">
                  <img
                    src={tick}
                    alt="tick"
                    className="w-full max-w-[30px] h-[26px]"
                  />
                  <p>Get access to higher and better loans.</p>
                </li>
              </ul>
              <Spacer size="37px" />
              <Link to="/search-loan">
                <TopNavRoundedButton className="btn boxer mx-auto md:mx-0">
                  Search loan
                </TopNavRoundedButton>
              </Link>
            </div>
            <div data-aos="fade-left" className="lg:-mr-[40px]">
              <img
                src={repay_graphic}
                alt="agent on call"
                className="w-full max-w-[1036px]"
              />
            </div>
          </div>
        </section>
        <div className="hidden md:block">
          <Spacer size="20px" />
        </div>
        <div className="md:hidden">
          <Spacer size="10px" />
        </div>
        <section className="hero w-100 overflow-hidden pad-alt pr-0">
          <div className="grid md:grid-cols-2 gap-y-3">
            <div
              className="left pr-[1.5rem] sm:pr-[3rem] md:pr-[6rem] lg:p-0"
              data-aos="fade-right"
            >
              <div className="hidden md:block">
                <Spacer size="108px" />
              </div>
              <div className="md:hidden">
                <Spacer size="54px" />
              </div>
              <h1
                className="font-boldAxiforma text-[45px] leading-[59px] text-dark font-extrabold"
                data-aos="fade-up"
              >
                Become a{" "}
                <span className="text-primary font-manrope">Remote</span>{" "}
                Restructure Call Agent today.{" "}
                <span className="text-accent">Earn a commission</span> on every
                amount recovered.
              </h1>
              <Spacer size="57px" />
              <Link to="/agents" className="block w-full max-w-[273px]">
                <button
                  data-aos="fade-up"
                  className="w-full text-white p-[19px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
                >
                  Become an agent
                </button>
              </Link>
              <div className="hidden md:block">
                <Spacer size="108px" />
              </div>
              <div className="md:hidden">
                <Spacer size="54px" />
              </div>
            </div>
            <div className="right" data-aos="fade-left">
              <img
                src={agent_call_image2}
                alt="agent dash one"
                className="w-full max-w-[739px]"
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
