import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NewLoanContext } from "..";
import Spacer from "../../../components/Spacer";
import { paymentFrequencyOptions } from "../../../utils/data/static";
import { add, format, sub } from "date-fns";
import {
  getFormattedNumber,
  getPaymentEndDate,
  nairaSymbol,
} from "../../../utils/functions";
import { NotificationContext } from "../../../context/notification-context";
import { multipartPostRequest } from "../../../utils/api";
import Button from "../../../components/Button";

export default function LoanAndPlanPreview() {
  const { formData, formIsValid, resetForm, setStep } =
    useContext(NewLoanContext);
  const [breakdown, setBreakdown] = useState<
    { label: string; amount: number }[]
  >([]);

  useEffect(() => {
    let amount = parseFloat(formData?.amount);
    let loan_amount = parseFloat(formData?.loan_amount?.toString());
    let installments = Math.ceil(
      parseFloat(formData?.loan_amount?.toString()) / amount
    );
    let freq = parseInt(formData.frequency);
    let start = formData?.payment_start_date;

    let data = [];

    for (let i = 1; i <= installments; i++) {
      data.push({
        label: format(
          add(new Date(start), { days: (i - 1) * freq }),
          "MMM dd, yyyy"
        ),
        amount:
          i == installments && loan_amount % amount
            ? loan_amount % amount
            : amount,
      });
    }

    setBreakdown(data);
  }, []);

  const [loading, setLoading] = useState(false);
  const { showErrorMessage, showSuccessMessage } =
    useContext(NotificationContext);
  const navigate = useNavigate();

  async function submit() {
    setLoading(true);
    try {
      let form = new FormData();
      for (let key of Object.keys(formData)) {
        form.append(key, (formData as any)[key]);
      }
      const {
        data: { status, message, data },
      } = await multipartPostRequest("/borrower_suggested_plan", form);

      if (status == "success") {
        showSuccessMessage(message);
        resetForm();
        setStep(4);
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message || "");
    }
    setLoading(false);
  }

  return (
    <section className="w-100 overflow-hidden pad-alt mt-[142px] mb-[104px]">
      <div className="w-full max-w-[953px] mx-auto">
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="w-full py-[63px] px-[35px] md:px-[182px] mb-[20px] border border-[#EBEBEB] rounded-[20px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)]"
        >
          <h4 className="text-[#1C1C1C] text-[28px] leading-[38.25px] font-semibold mb-[36px]">
            Suggested plan details
          </h4>
          <div className="grid gap-[26px] pb-[39px] border-b border-dark-100">
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>Outstanding loan amount</h4>
              <p className="font-normal whitespace-nowrap">
                {nairaSymbol}{" "}
                {getFormattedNumber(
                  parseFloat(formData?.loan_amount?.toString() || "0"),
                  2
                )}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>Frequency</h4>
              <p className="font-normal whitespace-nowrap">
                {paymentFrequencyOptions?.find(
                  (i: any) => i.value == formData?.frequency
                )?.name || ""}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>Instalment amount</h4>
              <p className="font-normal whitespace-nowrap">
                {nairaSymbol}{" "}
                {getFormattedNumber(
                  parseFloat(formData?.amount?.toString() || "0"),
                  2
                )}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>No of instalments</h4>
              <p className="font-normal whitespace-nowrap">
                {Math.ceil(
                  parseFloat(formData?.loan_amount?.toString()) /
                    parseFloat(formData?.amount)
                ) || "0"}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>Discount % suggested</h4>
              <p className="font-normal whitespace-nowrap">
                {formData?.discount || "0"}%
              </p>
            </div>
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>Start payment date</h4>
              <p className="font-normal whitespace-nowrap">
                {format(
                  formData?.payment_start_date
                    ? new Date(formData?.payment_start_date)
                    : new Date(),
                  "MMM d, yyyy"
                )}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-between text-dark-600 text-base font-semibold">
              <h4>Payment completion date</h4>
              <p className="font-normal whitespace-nowrap">
                {formData?.payment_start_date &&
                  formData?.loan_amount &&
                  formData?.amount &&
                  format(
                    getPaymentEndDate(formData)
                      ? new Date(getPaymentEndDate(formData))
                      : new Date(),
                    "MMM d, yyyy"
                  )}
              </p>
            </div>
          </div>
          <Spacer size="29px" />
          <h4 className="text-[#1C1C1C] text-[28px] leading-[38.25px] font-semibold mb-[23px]">
            Payment Breakdown
          </h4>
          <div className="grid gap-[26px] pb-[39px]">
            {breakdown.map((item) => (
              <div
                key={item.label}
                className="w-full flex items-center gap-4 justify-between text-dark-600 text-base font-semibold"
              >
                <h4>{item.label}</h4>
                <p className="font-normal whitespace-nowrap">
                  {nairaSymbol} {getFormattedNumber(item.amount, 2)}
                </p>
              </div>
            ))}
          </div>
          <Spacer size="64px" />
          <Button
            className="w-full mb-[33px] text-white p-[15px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
            disabled={!formIsValid}
            onClick={submit}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </section>
  );
}
