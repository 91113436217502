import React from "react";
import { Spacer } from "../../styles";
import map from "../../assets/images/map4.png";
import "./style.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { HeroCaption, HeroText } from "../../styles/styled";
const Aibanner = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="aibanner w-full pad-alt">
        <Spacer height="60px" heightSm="60px" />
        <div
          className="top-hero w-full relative justify-center flex rounded-[29px] overflow-hidden"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="row w-full p-0 m-0 relative">
            <div className="frame_bg m-0 w-full">
              <h5 className="text-center mx-auto header alt ">
                A.I powered Debt Recovery has never been easier
              </h5>{" "}
              <img src={map} alt="banner" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aibanner;
