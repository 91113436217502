import React, { Component } from "react";

import "./style.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import lenderBanner from "../../assets/images/old.png";
import borrowerBanner from "../../assets/images/new.png";
import tick from "../../assets/svgs/tick.svg";
import Spacer from "../../components/Spacer";
export const ExposaySection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="exposay-section w-100 overflow-hidden pad-alt">
        <div className="row gx-5 m-0 justify-content-center">
          <div className="col-md-6 ps-md-0 p-0" data-aos="fade-right">
            <img
              src={lenderBanner}
              alt=""
              className="img-fluid banner mt-md-4"
            />
          </div>{" "}
          <div className="md:hidden">
            <Spacer size="50px" />
          </div>
          <div className="col-md-6 order-md-first p-0 md:pr-3">
            <p
              className="blue-text text-uppercase fs-14 fw-bold"
              data-aos="fade-up"
            >
              for lenders
            </p>
            <h3 className="fs-50 bold-text caption-header" data-aos="fade-up">
              Easy, unified system for{" "}
              <span className="yellow-text">debt management</span>
            </h3>{" "}
            <div className="hidden md:block">
              <Spacer size="20px" />
            </div>
            <div className="md:hidden">
              <Spacer size="10px" />
            </div>
            <p
              className="text-start fw-bold"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              As a lender, Restructure empowers you will the smart tools you
              need to segment your debtors, create unique debt recovery
              campaigns for each segment with flexible repayment options. Once
              you select and customise your communication channels and launch
              your campaign, each debtor is sent a unique link with a customised
              dashboard to enable them payback their debts with ease.
            </p>
            <div className="hidden md:block">
              <Spacer size="20px" />
            </div>
            <div className="md:hidden">
              <Spacer size="10px" />
            </div>
            <p
              className=" fs-14 mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="400"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                Organise your recovery efforts with a comprehensive but simple
                Dashboard
              </span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img src={tick} />{" "}
              <span className="ms-3">Reduce recovery cost by 60%</span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                Reduce Manpower and man hours spent chasing debtors and
                defaulters
              </span>
            </p>
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img src={tick} />{" "}
              <span className="ms-3">Personalised Debt Recovery Dashboard</span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                Customer’s Segmentation and full automation
              </span>
            </p>
          </div>{" "}
        </div>
        <div className="hidden md:block">
          <Spacer size="164px" />
        </div>
        <div className="md:hidden">
          <Spacer size="85px" />
        </div>
        <div className="row gx-5 m-0 justify-content-center mt-4">
          {" "}
          <div className="col-md-6 ps-md-0" data-aos="fade-right">
            <img
              src={borrowerBanner}
              alt=""
              className="img-fluid banner mt-md-4"
            />
            <div className="md:hidden">
              <Spacer size="50px" />
            </div>
          </div>
          <div className="col-md-6 px-md-0">
            <p
              className="blue-text text-uppercase fs-14 fw-bold"
              data-aos="fade-up"
            >
              for customers
            </p>
            <h3 className="fs-50 bold-text caption-header" data-aos="fade-up">
              Easy and customizable
              <span className="yellow-text ms-3">loan repayment</span>
            </h3>{" "}
            <div className="hidden md:block">
              <Spacer size="20px" />
            </div>
            <div className="md:hidden">
              <Spacer size="10px" />
            </div>
            <p
              className="text-start fw-bold"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              RESTRUCTURE is designed to help people become debt free by
              providing them with automated, discounted and flexible repayment
              options tied to Milestone rewards.
              {/* Using Smart and alterrnative loan repayment from rollovers to
              maintain relative, proactive, flexible and installmental payment
              while retaining a good relationship. */}
            </p>
            <div className="hidden md:block">
              <Spacer size="20px" />
            </div>
            <div className="md:hidden">
              <Spacer size="10px" />
            </div>
            <p
              className=" fs-14 mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="400"
            >
              <img src={tick} />{" "}
              <span className="ms-3 ">
                {/* Upload customer data in csv */}
                Flexible repayment options with massive discounts
              </span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                {/* Personalize and take charge of lenders dashboard */}
                Loan tenure extensions
              </span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                {/* Personalize and take charge of lenders dashboard */}
                Rewards for Repayment Milestones
              </span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                {/* Personalize and take charge of lenders dashboard */}
                Credit Score improvements
              </span>
            </p>{" "}
            <p
              className=" fs-14  mb-4 d-flex"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <img src={tick} />{" "}
              <span className="ms-3">
                {/* Customer’s Segmentation and full automation */}
                Removal from Blacklist and access to more loans
              </span>
            </p>
          </div>{" "}
        </div>
      </section>
    </>
  );
};
