import React, { useEffect, useState } from "react";
import { Spacer } from "../../styles";
import { HeroCaption, InfoText, TabButton } from "../../styles/styled";
import Aibanner from "../../sections/A1banner/Aibanner";
import "./style.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { Icon } from "@iconify/react";

const STATIC_ACCORDIONS = [
  {
    id: 1,
    label: "Lenders (Companies)",
    data: [
      {
        title: "What are the different recovery methods used on Restructure?",
        content: "Restructure uses an automated recovery system.",
      },
      {
        title: "How do we sign up on Restructure?",
        content: "Simply log on to https://restructure.com.ng/ to sign up.",
      },
      {
        title: "How much does it cost to run a campaign on restructure?",
        content:
          "Standard package is pay-as –you-go, while Enterprise package cost $200 monthly software license.",
      },
      {
        title: "How long does it take to complete a single recovery campaign? ",
        content: "Currently, a recovery campaign can last up to 12weeks.",
      },
      {
        title: "Is the money recovered sent directly to clients?",
        content: "Money recovered is sent directly to Client’s wallet.",
      },
      {
        title:
          "Will we still have our NPL  portfolio if we commence our restructure campaigns?",
        content:
          "No, NPL portfolio use on Restructure will be exclusive to us within the agreed life time.",
      },
      {
        title: "What is the minimum or maximum debt value I can restructure?",
        content: "Currently, there is no minimum or maximum limit.",
      },
    ],
  },
  {
    id: 2,
    label: "Borrowers (Customers)",
    data: [
      {
        title: "Who are eligible for restructure?",
        content:
          "Customers with accounts and have defaulted on their loans facilities repayment with their respective lenders are eligible for restructuring.",
      },
      {
        title: "How do I apply for restructuring of the credit facilities?",
        content: "How do I apply for restructuring of the credit facilities?",
      },
      {
        title: "What are the restructuring options that are available?",
        content:
          "These include: Discount On Outstanding Loan Amount, Discounts or Forfeiture of Interest Amount, Loan Tenure Extension, Discount On Outstanding Loan Amount etc.",
      },
      {
        title:
          "Will opting for the restructuring have an impact on my credit score?",
        content:
          "Definitely, when you enroll on restructure and adhere to your plan, your credit score obviously improves.",
      },
      {
        title:
          "Will there be any processing fees or charges if I restructure my loan?",
        content: "There are No fees or charges when you restructure your loan.",
      },
      {
        title: "What type of borrowers are not eligible for Restructure?",
        content:
          "Only borrowers without account are not eligible for Restructure.",
      },
      {
        title: "I’m a self-employed individual, am I eligible for restructure?",
        content:
          "Yes, Restructure is designed for both individual and enterprises.",
      },
      {
        title:
          "If the borrower has applied for restructuring, can it be considered as having been accepted and implemented by the bank?",
        content: "Yes, Absolutely.",
      },
      {
        title:
          "Can a borrower apply for restructuring of partial outstanding of his/her total outstanding towards a credit facility?",
        content:
          "Borrowers can access this if it is among the restructure options.",
      },
      {
        title:
          "Will a borrower against whom bank initiated a legal recourse for recovery of dues be eligible for restructuring?",
        content:
          "Only borrowers who lenders have enrolled on the Restructure campaign can access the platform.",
      },
    ],
  },
];

function escapeRegExp(s) {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const Faq = () => {
  const [accordions, setAccordions] = useState(
    [...STATIC_ACCORDIONS].map((i) => ({ ...i, data: [...i?.data] }))
  );
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [openIndex, setOpenIndex] = useState(-1);
  const [search, setSearch] = useState("");

  async function filter() {
    console.log("Search", search);
    if (search?.length < 1)
      return (
        setAccordions((prev) =>
          [...STATIC_ACCORDIONS].map((i) => ({ ...i, data: [...i?.data] }))
        ),
        setOpenIndex(-1)
      );

    const words = search
      .split(/\s+/g)
      .map((s) => {
        return s.trim();
      })
      .filter((s) => !!s);

    const hasTrailingSpace = search.endsWith(" ");

    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );

    let data = [...STATIC_ACCORDIONS].map((i) => ({
      ...i,
      data: [...i?.data],
    }));
    let activeIndex = STATIC_ACCORDIONS?.findIndex(
      (i) => i?.id === activeAccordion
    );

    data[activeIndex].data = [
      ...data[activeIndex]?.data?.filter((i) => searchRegex.test(i?.title)),
    ];

    setAccordions((prev) => [...data]);

    setOpenIndex(-1);
  }

  useEffect(() => {
    if (search?.length < 1) {
      setAccordions((prev) =>
        [...STATIC_ACCORDIONS].map((i) => ({ ...i, data: [...i?.data] }))
      );
      setOpenIndex(-1);
    }
  }, [search]);

  useEffect(() => {
    if (search) {
      filter();
    }
  }, [activeAccordion]);

  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);

  return (
    <div>
      <section className="borrow-page w-100  position-relative pad-alt">
        <Spacer height="70px" heightSm="30px" />
        <HeroCaption
          className="faq pb-2 d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Frequently Asked Questions (FAQs)
        </HeroCaption>
        <InfoText data-aos="fade-up" data-aos-delay="200">
          Need answers? find them here.
        </InfoText>
        <div className="row gy-3 px-3 px-lg-5 px-md-5 px-xxl-5">
          <Spacer height="10px" heightSm="30px" />
          <div
            className="col-md-12 text-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="mt-2 sign_input_ctn justify-content-center d-flex">
              <input
                className="form-control rounded-l-[12px] rounded-r-none border-r-0"
                autoFocus
                placeholder="Enter your search(item) here"
                value={search}
                onInput={(e) => setSearch(e?.target?.value)}
              />
              <div>
                <button
                  className="rounded-r-[12px] shadow-[0px_4px_30px_rgba(0,_0,_0,_0.08)] border-primary border-l-0"
                  onClick={filter}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <Spacer height="70px" heightSm="30px" />
        </div>
      </section>

      <section className="w-100 pt-5 faq-section pad-alt">
        <div className="w-full flex gap-6 flex-wrap md:flex-nowrap">
          <div
            className="side-nav w-full md:max-w-[378px]"
            data-aos="fade-right"
          >
            {accordions?.map((accordion, idx) => (
              <button
                className={`nav-btn ${
                  activeAccordion == accordion.id ? "active" : ""
                }`}
                key={accordion?.id}
                onClick={async () => {
                  setActiveAccordion(accordion?.id);
                  setOpenIndex(-1);
                }}
              >
                {accordion?.label}
              </button>
            ))}
          </div>
          <div
            className="data-ctn w-full flex flex-col gap-4"
            data-aos="fade-left"
          >
            {accordions?.find((i) => i.id === activeAccordion)?.data?.length >
            0 ? (
              <>
                {" "}
                {accordions
                  ?.find((i) => i.id === activeAccordion)
                  ?.data?.map((item, idx) => (
                    <div className="accordion-ctn" data-aos="fade-up" key={idx}>
                      <div
                        className="accordion-title"
                        onClick={() =>
                          setOpenIndex((prev) => (prev === idx ? -1 : idx))
                        }
                      >
                        <h4 className="title">{item?.title}</h4>
                        <Icon
                          icon={`${
                            openIndex === idx
                              ? "mdi:chevron-down"
                              : "mdi:chevron-right"
                          }`}
                          className="icon"
                        />
                      </div>
                      <div
                        className={`accordion-content ${
                          openIndex === idx ? "" : "hidden"
                        }`}
                      >
                        {item?.content}
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <div className="accordion-ctn" data-aos="fade-up">
                <div className="accordion-title flex justify-center font-normal">
                  {search
                    ? "There no matches for your search query, try another keyword"
                    : "There are no faq data to show"}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Aibanner />
    </div>
  );
};

export default Faq;
