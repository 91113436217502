import { createContext, useEffect, useRef, useState } from "react";
import LoanDetailsForm from "./components/LoanDetailsForm";
import validator from "validator";
import { useLocation, useParams } from "react-router";
import SuggestPlanForm from "./components/SuggestPlanForm";
import Aos from "aos";
import LoanAndPlanPreview from "./components/LoanAndPlanPreview";
import { getPaymentEndDate } from "../../utils/functions";
import { add, format } from "date-fns";
import LoanSuccess from "./components/LoanSuccess";

const STATIC_FORM = {
  frequency: "",
  amount: "",
  discount: "",
  loan_amount: "",
  lender_name: "",
  lender_phone: "",
  lender_email: "",
  payment_start_date: "",
  search_id: "",
};

export const NewLoanContext = createContext({
  formData: { ...STATIC_FORM },
  setFormData: (data: typeof STATIC_FORM) => {},
  formErrors: { ...STATIC_FORM },
  setFormErrors: (data: typeof STATIC_FORM) => {},
  step: 1,
  setStep: (id: number) => {},
  handleInput: (e: any) => {},
  validateField: (data: typeof STATIC_FORM | any, key: string) => {},
  formIsValid: false,
  resetForm: () => {},
});

export default function NewLoan() {
  const state = useLocation().state;

  const [step, setStep] = useState(1);

  const steps = [
    { label: "Loan Details Form", id: 1, component: <LoanDetailsForm /> },
    { label: "Suggest Plan Form", id: 2, component: <SuggestPlanForm /> },
    {
      label: "Loan And Plan Preview",
      id: 3,
      component: <LoanAndPlanPreview />,
    },
    {
      label: "Loan Success",
      id: 4,
      component: <LoanSuccess />,
    },
  ];

  const [formData, setFormData] = useState({
    ...STATIC_FORM,
    search_id: useParams()?.id || "",
    loan_amount: state?.loan?.total_debt?.toString() || "",
    lender_name: state?.loan?.company_name?.toString() || "",
  });

  const [formErrors, setFormErrors] = useState({ ...STATIC_FORM });

  const [formIsValid, setFormIsValid] = useState(false);

  function handleInput(e: any) {
    let name = e?.target?.name;
    let val = e?.target?.value;

    let data = { ...formData, [name]: val };

    setFormData(data);

    validateField(data, name);
  }

  function validateField(data: typeof STATIC_FORM | any, key: string) {
    if (
      !["lender_phone", "lender_email", "interest"]?.includes(key) &&
      validator.isEmpty(data[key].toString())
    ) {
      setFormErrors((prev) => ({ ...prev, [key]: "Field is required" }));
    } else if (
      ["loan_amount", "frequency", "amount", "discount"].includes(key) &&
      !validator.isNumeric(data[key]?.toString())
    ) {
      setFormErrors((prev) => ({
        ...prev,
        [key]: "Field can only contain numbers",
      }));
    } else if (
      key.includes("email") &&
      data[key] &&
      !validator.isEmail(data[key]?.toString())
    ) {
      setFormErrors((prev) => ({
        ...prev,
        [key]: "Invalid email address",
      }));
    } else if (
      ["frequency", "amount", "payment_start_date"].includes(key) &&
      parseFloat(data["loan_amount"].toString()) < 1000000
    ) {
      let end_date = new Date(getPaymentEndDate(data));
      console.log(end_date);
      let start = new Date(
        data?.payment_start_date?.toString() || new Date().toISOString()
      );
      let amount = parseFloat(data?.loan_amount?.toString());
      if (amount < 50000 && end_date > add(start, { days: 90 })) {
        setFormErrors((prev) => ({
          ...prev,
          amount:
            "Amount must be high enough to complete on " +
            format(add(start, { days: 90 }), "MMM d, yyyy"),
        }));
      } else if (amount < 200000 && end_date > add(start, { days: 180 })) {
        setFormErrors((prev) => ({
          ...prev,
          amount:
            "Amount must be high enough to complete on " +
            format(add(start, { days: 180 }), "MMM d, yyyy"),
        }));
      } else if (end_date > add(start, { days: 365 })) {
        setFormErrors((prev) => ({
          ...prev,
          amount:
            "Amount must be high enough to complete on " +
            format(add(start, { days: 365 }), "MMM d, yyyy"),
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          amount: "",
        }));
      }
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  }

  const valid_count = useRef(0);

  useEffect(() => {
    if (valid_count.current > 0) {
      if (Object?.values(formErrors)?.join("")?.length > 0) {
        setFormIsValid(false);
      } else {
        setFormIsValid(true);
      }
    }
    return () => {
      valid_count.current++;
    };
  }, [formErrors, formData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);

  function resetForm() {
    setStep(1);
    setFormData({ ...STATIC_FORM });
    setFormErrors({ ...STATIC_FORM });
  }

  return (
    <div>
      <div className="relative">
        <NewLoanContext.Provider
          value={{
            formData,
            setFormData,
            formErrors,
            setFormErrors,
            step,
            setStep,
            handleInput,
            validateField,
            formIsValid,
            resetForm,
          }}
        >
          {steps.find((i) => i.id == step)?.component}
        </NewLoanContext.Provider>
      </div>
    </div>
  );
}
