import React from "react";
import { Spacer } from "../../styles";
import { HeroCaption } from "../../styles/styled";
import preamble from "../../assets/images/preamble.png";
import problem from "../../assets/images/problem.png";
import "./style.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import analyst from "../../assets/images/data_analyst.png";
const Solutionstepsection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);

  return (
    <>
      <section className="step-solution w-100 pad-alt">
        <div className="container-fluid  p-0 pt-5 ">
          <Spacer height="00px" heightSm="0px" />

          <div className=" position-relative ">
            <div className="row w-100 p-0 m-0 position-relative">
              <div className="row">
                <div
                  className="col-md-6 mt-4 pream-png"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <Spacer
                    height={"50px"}
                    heightSm={"20px"}
                  />

                  <img
                    src={preamble}
                    className="img-fluid img-banner"
                    alt="banner"
                  />
                </div>

                {/* <Spacer height="00px" heightSm="0px" /> */}
                <div className="col-md-6 mt-5 ">
                  <HeroCaption className="fs-2 text-center">
                    Preamble
                  </HeroCaption>
                  <div className="text ">
                    {" "}
                    <p
                      data-aos="fade-left"
                      data-aos-delay="600"
                    >
                      Credit is critical to growing an
                      economy, however, while it's gotten
                      easier to access credit,
                      non-performing loans are on the rise.
                      <br /> Nigeria Non-performing loans
                      was reported at $3.3 Billion in June
                      2022A. This does not take into account
                      millions of Dollars in uncollected
                      debt not reported by hundreds of
                      Fintech lenders and non-formal lending
                      companies. This has resulted to
                      lenders sometimes suspending their
                      operations, or totally folding up.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spacer height="70px" heightSm="0px" />
        <div className="container-fluid  p-0   second-desk ">
          <Spacer height="00px" heightSm="0px" />

          <div className=" position-relative ">
            <div className="row w-100 p-0 m-0 position-relative">
              <div className="row">
                <Spacer height="00px" heightSm="0px" />

                <div
                  className="col-md-6 mt-5 "
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <HeroCaption className="fs-2 ">
                    Problem
                  </HeroCaption>
                  <aside className="  mb-5 ">
                    One of the major reasons for rising
                    non-performing loans (NPL) is due to
                    inadequate credit checks by lenders, on
                    the other hand, debtors are unable to
                    pay back sometimes for understandable
                    reasons. Or become elusive due to
                    threatening collection processes by some
                    lenders and collection agencies.
                  </aside>
                </div>
                <div
                  className="col-md-6 mt-2 problem-png"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  {/* <Spacer
                    height={"50px"}
                    heightSm={"50px"}
                  />

                  <Spacer
                    height={"50px"}
                    heightSm={"50px"}
                  /> */}
                  <img
                    src={problem}
                    className="img-fluid img-banner"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  p-0 pt-5  second-desk">
          <Spacer height="00px" heightSm="0px" />

          <div className=" position-relative ">
            <div className="row w-100 p-0 m-0 position-relative">
              <div className="row">
                {/* <Spacer height="00px" heightSm="0px" /> */}

                <div
                  className="col-md-6 mt-4 problem-png"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  {/* <Spacer
                    height={"50px"}
                    heightSm={"20px"}
                  /> */}

                  <img
                    src={analyst}
                    className="img-fluid img-banner"
                    alt="banner"
                  />
                </div>

                <div
                  className="col-md-6 mt-5 "
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <HeroCaption className="fs-2 text-center">
                    Solution
                  </HeroCaption>
                  <div className="justify-content-center d-flex">
                    <aside className="">
                      Restructure provides a system that
                      enables lenders collect their debt
                      while helping debtors become debt free
                      via flexible, customisable repayment
                      options which are tied to rewards.
                      Additionally, Restructure provides
                      versatile credit checks and automates
                      the lending and credit decision
                      process for Fintech lenders through
                      API calls.
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solutionstepsection;
