import { Spacer } from "../../styles";
import React from "react";
import { HeroCaption } from "../../styles/styled";
import "./style.scss";
import reward from "../../assets/images/reward.png";
import community from "../../assets/images/community.png";
import centric from "../../assets/images/centric.png";
import privacy from "../../assets/images/privacy.png";
import mrd from "../../assets/images/mrd.png";
import joseph from "../../assets/images/joseph.png";
import coo from "../../assets/images/coo.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Values = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);

  return (
    <>
      <section className="value-hero-section w-100 pad-alt">
        <div className="  pt-5 ">
          <Spacer height="00px" heightSm="0px" />
          <div className="top-hero position-relative ">
            <div className=" w-100 p-0 m-0 position-relative ">
              <div className="w-100 row d-flex justify-content-center m-0 g-5  val-image ">
                <Spacer height="00px" heightSm="0px" />
                <HeroCaption className="text-center fs-2">
                  Our Values
                </HeroCaption>
                <Spacer
                  height={"50px"}
                  heightSm={"30px"}
                  className="m-0"
                />
                <div
                  className="col-md-3 flex flex-col items-center col-sm-6 mb-1 pb-1 "
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img src={reward} className="img-fluid" />
                  <h3 className="caption-header mb-2 mt-2">
                    Integrity
                  </h3>
                  <p className="caption-subheader ">
                    We strive to to the right things always
                  </p>
                </div>{" "}
                <div
                  className="col-md-3 flex flex-col items-center col-sm-6 "
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img
                    src={privacy}
                    className="img-fluid"
                  />
                  <h3 className="caption-header mb-4 mt-2">
                    Data and Privacy
                  </h3>
                  <p className="caption-subheader">
                    We are committed to protecting our
                    Customer’s data
                  </p>
                </div>{" "}
                <div
                  className="col-md-3 flex flex-col items-center col-sm-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img
                    src={centric}
                    className="img-fluid"
                  />
                  <h3 className="caption-header mb-4 mt-2">
                    Customer-Centric
                  </h3>
                  <p className="caption-subheader">
                    Understanding our customer’s needs and
                    designing solutions that deliver the
                    best.
                  </p>
                </div>{" "}
                <div
                  className="col-md-3 flex flex-col items-center col-sm-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img
                    src={community}
                    className="img-fluid"
                  />
                  <h3 className="caption-header mb-4 mt-2">
                    Community
                  </h3>
                  <p className="caption-subheader">
                    We are designed to serve different
                    categories of people
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
        <Spacer height={"50px"} heightSm={"20px"} />

        <div className="w-100 pad-alt  founders-img ">
          <div className=" pt-5  text">
            <div className="row">
              <Spacer height={"50px"} heightSm={"20px"} />
              <div
                className="col-md-4 flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img src={mrd} className="img-fluid" />
                <h4 className="caption-header mb-2 mt-2 ">
                  Darlington Onyeagoro
                </h4>
                <p className="text-center">
                  Founder and CEO
                </p>
              </div>{" "}
              <div
                className="col-md-4 flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img src={joseph} className="img-fluid" />
                <h4 className="caption-header mb-2 mt-2 ms-2">
                  Joseph Ojighoro
                </h4>
                <p className="text-center ">CTO</p>
              </div>{" "}
              <div
                className="col-md-4 flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img src={coo} className="img-fluid" />
                <h3 className="caption-header mb-2 mt-2 ">
                    Ku Usoroh
                </h3>
                <p className="text-center ">COO</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Values;
