import { useState } from "react";
import React from "react";
import { Spacer } from "../../styles";
import { HeroCaption } from "../../styles/styled";
import lend from "../../assets/images/lend.png";
import borrow from "../../assets/images/borrows.png";
import mobile from "../../assets/images/mobile_point.png";
import insight from "../../assets/images/insight.png";
import tick from "../../assets/svgs/tick.svg";
import flow from "../../assets/images/flow.png";
import Aos from "aos";
import "aos/dist/aos.css";
import "./style.scss";
const Productsection = () => {
  const [otherImage, setOtherImage] = useState(false);

  const imageChanger = () => {
    if (window.innerWidth <= 800) {
      return setOtherImage(true);
    } else {
      return setOtherImage(false);
    }
  };
  window.addEventListener("resize", imageChanger);
  window.addEventListener("load", imageChanger);
  window.addEventListener("scroll", imageChanger);
  window.addEventListener("focus", imageChanger);

  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="product-section  w-100 overflow-hidden pad-alt">
        <div className=" gx-5 m-0 justify-content-center ">
          <Spacer height={"70px"} heightSm={"40px"} />
          <HeroCaption className="fs-2 mt-5 text-center">
            Our Products
          </HeroCaption>
          {/* <div className="content "> */}
          {/* <Spacer height={"50px"} heightSm={"20px"} /> */}
          <div className="row content  justify-content-center mt-5">
            <div className="col-md-4 col-sm-6">
              <div className="lenders">
                <div className="top d-flex justify-content-center mt-3">
                  {" "}
                  <img src={lend} alt="img" />
                </div>
                <p className=" fs-14  mb-1 d-flex mt-4">
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />
                  <span className="ms-3">
                    Create Customizable payment Options
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Debtors Bucketisation
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Dashboard Report & Analytics
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Actionable Data from customer engagement
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Create Multiple Campaigns
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Effective Broadcast Channels
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Customizable Reward Options
                  </span>
                </p>{" "}
                <p
                  className=" fs-16  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Set customer reminder channels and
                    frequency
                  </span>
                </p>{" "}
                <p className=" fs-16  mb-1 d-flex">
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Enjoy loan buy back from other lenders
                  </span>
                </p>{" "}
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="borrow top">
                <div className="justify-content-center d-flex mt-3 top_png">
                  <img src={borrow} alt="img" />
                </div>
                <p
                  className=" fs-14  mb-1 d-flex mt-4"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />
                  <span className="ms-3">
                    Flexible Customizable Payment Options
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Customer's preferred Channel
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Debt Consolidation
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Milestone payment Rewards
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Better credit score
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Seamless payment channels
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Loan buy back options
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Debt Freedom Tips
                  </span>
                </p>{" "}
                <p className=" fs-14  mb-1 d-flex">
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Access to loans
                  </span>
                </p>{" "}
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="insight top">
                <div className="justify-content-center d-flex mt-3">
                  <img src={insight} alt="img" />
                </div>
                <p
                  className=" fs-14  mb-1 d-flex mt-4"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Access real-time Data of chronic
                    defaulters
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Access multiple credit data sources
                    through single
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">interface</span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Improve your risk assessment process
                  </span>
                </p>{" "}
                <p
                  className=" fs-14  mb-1 d-flex"
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  <img
                    src={tick}
                    alt="img"
                    style={{
                      width: "width: 20px",
                      height: "20px",
                    }}
                  />{" "}
                  <span className="ms-3">
                    Get paid anytime your data gets hit
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        <div className="row m-0 justify-content-center">
          <div className="col-md-12 justify-content-center d-flex mt-5">
            <HeroCaption className="fs-2 mt-5">
              Our Process Flow
            </HeroCaption>
          </div>
          <div className="col-sm-12 d-flex py-3 px-5">
            <img
              src={otherImage ? mobile : flow}
              alt="restructure flow"
              className="mx-auto img-fluid"
              data-aos="fade-up"
            />
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default Productsection;
