import React from "react";
import Aos from "aos";
import Aibanner from "../../sections/A1banner/Aibanner";
import Aboutus from "../../sections/aboutus/aboutus";
import Values from "../../sections/value/values";

const Aboutpage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section>
        <Aboutus />
        <Values />
        <Aibanner />
      </section>
    </>
  );
};

export default Aboutpage;
