import { Icon } from "@iconify/react";
import logo_light from "../../assets/svgs/logo_light.svg";
import profile_image from "../../assets/images/profile_image.png";
import "aos/dist/aos.css";
import Aos from "aos";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Login() {
  const [selected, setSelected] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 200,
    });
    setTimeout(() => Aos.refreshHard(), 300);
  }, []);
  return (
    <div className="h-screen grid md:grid-cols-2">
      <div className="left md:h-full bg-[linear-gradient(165.4deg,_#009DDF_34.7%,_#002093_96.97%)] p-8 hidden md:flex flex-col">
        <div className="logo">
          <Link to="/">
            <img src={logo_light} alt="" data-aos="fade-right" />
          </Link>
        </div>
        <div className="h-full flex flex-col justify-center">
          <div
            data-aos="fade-up"
            className="stars flex items-center gap-1 w-fit mx-auto mb-[32px] text-[20px] text-[#FEC84B]"
          >
            <Icon icon="mdi:star" />
            <Icon icon="mdi:star" />
            <Icon icon="mdi:star" />
            <Icon icon="mdi:star" />
            <Icon icon="mdi:star" />
          </div>
          <div
            data-aos="fade-up"
            className="w-full max-w-[440px] mx-auto text-center text-[26px] leading-8 text-white"
          >
            Restructure is designed to facilitate the relationship between
            lenders and borrowers by helping both achieve their respective goals
            of recovering their loans and becoming debt free.
          </div>
        </div>
        <div className="footer flex justify-between text-sm text-[#E9D7FE]">
          <p data-aos="fade-right">© Restructure 2022</p>
          <p className="flex gap-1 items-center" data-aos="fade-left">
            <Icon icon="mdi:email-outline" className="grid items-center" />{" "}
            info@restructure.com.ng
          </p>
        </div>
      </div>
      <div className="right md:h-full p-8">
        <h2
          data-aos="fade-up"
          className="text-center text-[#101828] text-3xl leading-[38px] font-semibold mt-[80px] mb-[18px]"
        >
          Log in
        </h2>
        <p
          data-aos="fade-up"
          className="text-center text-[#667085] text-base mb-[70px]"
        >
          Please select an account type to proceed
        </p>
        <div
          data-aos="fade-up"
          className="w-full max-w-[440px] grid grid-cols-2 gap-3 mx-auto mb-[45px]"
        >
          <div
            className={`one grid place-items-center border-2 border-solid hover:border-primary ${
              selected == "lenders" ? "border-primary" : "border-[#101828]"
            } p-[28px] cursor-pointer rounded-[10px]`}
            onClick={() => setSelected("lenders")}
          >
            <img
              src={profile_image}
              alt="profile image"
              className="w-[60px] h-[60px] rounded-full mb-[3px]"
            />
            <h4 className="text-[#101828] text-sm leading-[38px] font-medium">
              Lenders
            </h4>
          </div>
          <div
            className={`one grid place-items-center border-2 border-solid hover:border-primary  ${
              selected === "borrowers" ? "border-primary" : "border-[#101828]"
            } p-[28px] cursor-pointer rounded-[10px]`}
            onClick={() => setSelected("borrowers")}
          >
            <img
              src={profile_image}
              alt="profile image"
              className="w-[60px] h-[60px] rounded-full mb-[3px]"
            />
            <h4 className="text-[#101828] text-sm leading-[38px] font-medium">
              Borrowers
            </h4>
          </div>
        </div>
        <div className="btn flex justify-center mb-[60px]">
          <a
            href={
              selected == "borrowers"
                ? "https://users.restructure.com.ng/"
                : selected == "lenders"
                ? "https://app.restructure.com.ng/"
                : undefined
            }
            className="block w-full max-w-[200px]"
            data-aos="fade-up"
          >
            <button
              disabled={!selected}
              className="btn outline-transparent focus:outline-transparent active:outline-transparent p-[16px] text-white text-sm text-center bg-primary rounded-[10px] w-full whitespace-nowrap"
            >
              <span>Continue</span>
              <Icon icon="mdi:arrow-right" className="inline-flex ml-4" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
