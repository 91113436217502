import * as React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/svgs/dlogo.svg";
import line from "../../assets/svgs/nav-line.svg";
import {
  TopNavRoundedButton,
  TopNavLinkStyled,
  TopNavLinkAltStyled,
} from "../../styles/styled";
import Aos from "aos";
import "aos/dist/aos.css";
import "./style.scss";

export const Nav = () => {
  const [toggle, setToggle] = React.useState(false);
  const NavToggler = () => {
    setToggle(!toggle);
  };
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });

    if (toggle) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  });

  React.useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768) {
        document.body.style.overflowY = "auto";
        setToggle(false);
      }
    });
  });
  return (
    <>
      <nav
        className={
          toggle
            ? "nav-section open pad-alt z-[100] relative"
            : "nav-section pad-alt z-[100] relative"
        }
      >
        <div className="container-fluid  py-3">
          <div className="row justify-content-between">
            <div
              className="col-auto d-flex align-items-start pl-0 relative -left-3"
              data-aos="fade-right"
            >
              <Link
                to="/"
                className={toggle ? " d-hide-sm logo-icon" : "logo-icon"}
              >
                <img className="img-fluid" src={logo} />
              </Link>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center d-hide-sm">
              <ul
                className="navlist-lg gap-[15px] whitespace-nowrap"
                data-aos="fade-right"
              >
                <li>
                  <Link to="/">
                    <TopNavLinkStyled>Home</TopNavLinkStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/solutions">
                    <TopNavLinkStyled>Solutions</TopNavLinkStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/package">
                    <TopNavLinkAltStyled>Packages</TopNavLinkAltStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/agents">
                    <TopNavLinkAltStyled>Agents</TopNavLinkAltStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/search-loan">
                    <TopNavLinkAltStyled>Search Loan</TopNavLinkAltStyled>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-auto d-hide-sm d-flex align-items-center justify-end pr-0">
              <Link to="/login" className="me-4" data-aos="fade-left">
                <TopNavLinkAltStyled>Sign In</TopNavLinkAltStyled>
              </Link>
              <TopNavRoundedButton
                href="https://app.restructure.com.ng/createaccount/"
                className="btn "
                data-aos="fade-left"
              >
                Create Account
              </TopNavRoundedButton>
            </div>
            <div className="col-auto d-flex align-items-center d-hide-lg">
              <button
                onClick={() => {
                  NavToggler();
                }}
                className={toggle ? "nav-toggler btn open" : "nav-toggler btn "}
              >
                <img src={line} alt="" />
                <img src={line} alt="" />
                <img src={line} alt="" />
              </button>
            </div>
            <div
              className={
                toggle
                  ? "col-12  d-hide-lg h-100 px-4 py-5 navlist-sm-ctn open h-80"
                  : "col-12  d-hide-lg h-100 px-4 py-5 navlist-sm-ctn "
              }
            >
              <ul
                className="navlist-sm my-auto grid gap-[20px]"
                onClick={() => {
                  NavToggler();
                }}
              >
                <li>
                  <Link to="/">
                    <TopNavLinkStyled>Home</TopNavLinkStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/solutions">
                    <TopNavLinkStyled>Solutions</TopNavLinkStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/package">
                    <TopNavLinkAltStyled>Packages</TopNavLinkAltStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/agents">
                    <TopNavLinkAltStyled>Agents</TopNavLinkAltStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/search-loan">
                    <TopNavLinkAltStyled>Search Loan</TopNavLinkAltStyled>
                  </Link>
                </li>
                <li>
                  <Link to="/login">
                    <TopNavLinkAltStyled>Sign In</TopNavLinkAltStyled>
                  </Link>
                </li>
                <li>
                  <TopNavRoundedButton
                    href="https://app.restructure.com.ng/createaccount/"
                    className="btn mx-auto"
                  >
                    Create Account
                  </TopNavRoundedButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
