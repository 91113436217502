import { Icon } from "@iconify/react";
import { twMerge } from "tw-merge";

export default function Button({ loading, className, children, ...rest }: any) {
  return (
    <button
      className={twMerge(
        "w-full flex items-center justify-center gap-2 py-[13px] px-[20px] text-sm leading-[19px] text-center font-bold disabled:bg-accent2-200 disabled:bg-opacity-100 rounded-[8px] " +
          className
      )}
      {...rest}
    >
      {loading && <Icon icon="mdi:loading" className="text-2xl animate-spin" />}
      {children}
    </button>
  );
}
