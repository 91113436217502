import Aos from "aos";
import {
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import search_loan_hero from "../../assets/images/search-hero-right-image.png";
import search_bg from "../../assets/images/search-hero-bg-art.png";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Button from "../../components/Button";
import { NotificationContext } from "../../context/notification-context";
import { multipartPostRequest } from "../../utils/api";

// Import Swiper styles
import Swiper from "swiper";
import { SwiperOptions } from "swiper/types";
import { register } from "swiper/element/bundle";
import { SwiperSlideProps } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/autoplay";
import { useMediaQuery } from "react-responsive";

register();

type Kebab<
  T extends string,
  A extends string = ""
> = T extends `${infer F}${infer R}`
  ? Kebab<R, `${A}${F extends Lowercase<F> ? "" : "-"}${Lowercase<F>}`>
  : A;

/**
 * Helper for converting object keys to kebab case because Swiper web components parameters are available as kebab-case attributes.
 * @link https://swiperjs.com/element#parameters-as-attributes
 */
type KebabObjectKeys<T> = {
  [key in keyof T as Kebab<key & string>]: T[key] extends Object
    ? KebabObjectKeys<T[key]>
    : T[key];
};

/**
 * Swiper 9 doesn't support Typescript yet, we are watching the following issue:
 * @link https://github.com/nolimits4web/swiper/issues/6466
 *
 * All parameters can be found on the following page:
 * @link https://swiperjs.com/swiper-api#parameters
 */
type SwiperRef = HTMLElement & { swiper: Swiper; initialize: () => void };

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": SwiperContainerAttributes;
      "swiper-slide": SwiperSlideAttributes;
    }

    interface SwiperContainerAttributes extends KebabObjectKeys<SwiperOptions> {
      ref?: RefObject<SwiperRef>;
      children?: ReactNode;
    }
    interface SwiperSlideAttributes extends KebabObjectKeys<SwiperSlideProps> {
      key?: any;
    }
  }
}

export default function SearchLoan() {
  const [bvn, setBvn] = useState("");
  const [inputErr, setInputErr] = useState("");

  const effect_count = useRef(0);

  useEffect(() => {
    if (effect_count.current > 0) {
      if (validator.isEmpty(bvn)) {
        setInputErr("Field is required");
      } else if (!validator.isNumeric(bvn)) {
        setInputErr("Only number are allowed here");
      } else if (!validator.isLength(bvn, { max: 11, min: 11 })) {
        setInputErr("BVN must be exactly 11 digits");
      } else {
        setInputErr("");
      }
    }
    return () => {
      effect_count.current++;
    };
  }, [bvn]);

  useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const { showErrorMessage, showSuccessMessage } =
    useContext(NotificationContext);
  const navigate = useNavigate();

  async function submit() {
    setLoading(true);
    try {
      let form = new FormData();
      form.append("bvn", bvn);
      const {
        data: { status, message, data },
      } = await multipartPostRequest("/search_loans", form);

      if (status == "success") {
        showSuccessMessage(message);
        setBvn("");
        navigate("/search-loan/result", {
          state: { user: { id: data?.info?.user } },
        });
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message || "");
    }
    setLoading(false);
  }

  const slides = [
    {
      id: 1,
      text: "Enter your bvn and click search to see the list of your loans",
      stroke: "#009DDF",
    },
    {
      id: 2,
      text: "Enter the six digits code sent to the phone number your bvn is registered with to verify your bvn",
      stroke: "#FF9E00",
    },
    {
      id: 3,
      text: "Once your bvn is verified, you will see your loan(s) and your lender(s)",
      stroke: "#009DDF",
    },
    {
      id: 4,
      text: "Click on view restructure plan to see the list of available repayment options you can choose from",
      stroke: "#FF9E00",
    },
  ];

  const xs = useMediaQuery({ minWidth: 250, maxWidth: 375 });
  const sm = useMediaQuery({ minWidth: 378, maxWidth: 450 });
  const sml = useMediaQuery({ minWidth: 451, maxWidth: 600 });
  const md = useMediaQuery({ minWidth: 601, maxWidth: 800 });
  const mdl = useMediaQuery({ minWidth: 801, maxWidth: 900 });
  const lg = useMediaQuery({ minWidth: 901, maxWidth: 1080 });
  const xl = useMediaQuery({ minWidth: 1080, maxWidth: 1240 });

  return (
    <div>
      <div className="relative">
        <section
          className="hero h-fit max-h-fit bg-no-repeat w-full -mt-[80px] md:-mt-[150px] lg:-mt-[80px] mb-[80px] w-100 overflow-x-hidden"
          style={{ backgroundImage: `url('${search_bg}')` }}
        >
          <div className="flex pt-[176px] pb-[116px] flex-wrap gap-x-[178px] gap-y-[40px] lg:flex-nowrap pad-alt">
            <div
              data-aos="fade-right"
              className="text-area relative z-[10] pt-[calc(264px-176px)] w-full md:min-w-[541px] md:max-w-[800px]"
            >
              <h1 className="text-[54px] mb-[25px] font-boldAxiforma font-extrabold leading-[55.61px]">
                <span className="text-[#000000] font-manrope leading-[55.61px]">
                  Let us support you with
                </span>
                <span className="text-[#FF9E00] leading-[67.82px]">
                  <br />
                  more loan
                </span>
                <span className="text-[#000000] font-manrope leading-[55.61px]">
                  <br />
                  opportunities
                </span>
              </h1>
              <p className="text-[#555555] text-xl font-mediumAxiforma font-medium leading-[125%]">
                As a borrower, take charge of your loan by determining the
                repayment schedule that works best for you.
              </p>
            </div>
            <div className="w-full md:max-w-[488px]">
              <img
                src={search_loan_hero}
                alt="hero image"
                data-aos="fade-left"
                className="w-full"
              />
            </div>
          </div>
          <div className="carousel relative">
            <div className="w-fit absolute left-0 top-0">
              <div className="w-[16px] sm:w-[35px] md:w-[40px] lg:w-[70px] mb-[40px]  h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] rounded-r-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px] border-t-[#FF9E00]"></div>
            </div>
            <div className="w-full overflow-hidden pad-alt sm:hidden">
              <swiper-container
                modules={[Autoplay] as any}
                slides-per-view={1}
                grid-rows={1}
                mousewheel-force-to-axis="true"
                autoplay={true}
              >
                {slides.map((slide, idx) => (
                  <swiper-slide key={slide.id}>
                    <div
                      className="w-[295px] md:w-[273px] mb-[40px] h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] px-[36px] rounded-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px]"
                      style={{
                        borderTopColor: slide.stroke,
                        ...(idx == 0 && { marginLeft: "10px" }),
                        ...(idx == slides.length - 1 && {
                          marginRight: "10px",
                        }),
                      }}
                    >
                      <h4 className="text-[rgba(17,_17,_17,_1)] text-xl font-boldAxiforma leading-[25.4px] mb-[13px]">
                        Step {slide.id}
                      </h4>
                      <p className="text-[rgba(85,_85,_85,_1)] text-base">
                        {slide.text}
                      </p>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
            <div className="w-full overflow-hidden pad-alt hidden sm:block md:hidden">
              <swiper-container
                modules={[Autoplay] as any}
                slides-per-view={2}
                grid-rows={1}
                mousewheel-force-to-axis="true"
                autoplay={true}
              >
                {slides.map((slide, idx) => (
                  <swiper-slide key={slide.id}>
                    <div
                      className="w-[295px] sm:w-[273px] mb-[40px] h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] px-[36px] rounded-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px]"
                      style={{
                        borderTopColor: slide.stroke,
                        ...(idx == 0 && { marginLeft: "10px" }),
                        ...(idx == slides.length - 1 && {
                          marginRight: "10px",
                        }),
                      }}
                    >
                      <h4 className="text-[rgba(17,_17,_17,_1)] text-xl font-boldAxiforma leading-[25.4px] mb-[13px]">
                        Step {slide.id}
                      </h4>
                      <p className="text-[rgba(85,_85,_85,_1)] text-base">
                        {slide.text}
                      </p>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
            <div className="w-full overflow-hidden pad-alt hidden md:block lg:hidden">
              <swiper-container
                modules={[Autoplay] as any}
                slides-per-view={2.8}
                grid-rows={1}
                mousewheel-force-to-axis="true"
                autoplay={true}
              >
                {slides.map((slide, idx) => (
                  <swiper-slide key={slide.id}>
                    <div
                      className="w-[295px] sm:w-[273px] mb-[40px] h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] px-[36px] rounded-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px]"
                      style={{
                        borderTopColor: slide.stroke,
                        ...(idx == 0 && { marginLeft: "10px" }),
                        ...(idx == slides.length - 1 && {
                          marginRight: "10px",
                        }),
                      }}
                    >
                      <h4 className="text-[rgba(17,_17,_17,_1)] text-xl font-boldAxiforma leading-[25.4px] mb-[13px]">
                        Step {slide.id}
                      </h4>
                      <p className="text-[rgba(85,_85,_85,_1)] text-base">
                        {slide.text}
                      </p>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
            <div className="w-full overflow-hidden pad-alt hidden lg:block xl:hidden">
              <swiper-container
                modules={[Autoplay] as any}
                slides-per-view={3.3}
                grid-rows={1}
                mousewheel-force-to-axis="true"
                autoplay={true}
              >
                {slides.map((slide, idx) => (
                  <swiper-slide key={slide.id}>
                    <div
                      className="w-[295px] sm:w-[273px] mb-[40px] h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] px-[36px] rounded-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px]"
                      style={{
                        borderTopColor: slide.stroke,
                        ...(idx == 0 && { marginLeft: "10px" }),
                        ...(idx == slides.length - 1 && {
                          marginRight: "10px",
                        }),
                      }}
                    >
                      <h4 className="text-[rgba(17,_17,_17,_1)] text-xl font-boldAxiforma leading-[25.4px] mb-[13px]">
                        Step {slide.id}
                      </h4>
                      <p className="text-[rgba(85,_85,_85,_1)] text-base">
                        {slide.text}
                      </p>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
            <div className="w-full overflow-hidden pad-alt hidden xl:block">
              <swiper-container
                modules={[Autoplay] as any}
                slides-per-view={3.5}
                grid-rows={1}
                mousewheel-force-to-axis="true"
                autoplay={true}
              >
                {slides.map((slide, idx) => (
                  <swiper-slide key={slide.id}>
                    <div
                      className="w-[295px] sm:w-[273px] mb-[40px] h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] px-[36px] rounded-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px]"
                      style={{
                        borderTopColor: slide.stroke,
                        ...(idx == 0 && { marginLeft: "10px" }),
                        ...(idx == slides.length - 1 && {
                          marginRight: "10px",
                        }),
                      }}
                    >
                      <h4 className="text-[rgba(17,_17,_17,_1)] text-xl font-boldAxiforma leading-[25.4px] mb-[13px]">
                        Step {slide.id}
                      </h4>
                      <p className="text-[rgba(85,_85,_85,_1)] text-base">
                        {slide.text}
                      </p>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
            <div className="w-fit absolute right-0 top-0">
              <div className="w-[16px] sm:w-[35px] md:w-[40px] lg:w-[70px] mb-[40px] h-[214px] bg-[rgba(255,_255,_255,_0.2)] py-[24px] rounded-l-[16px] shadow-[0px_8px_12px_0px_rgba(0,_0,_0,_0.12)] border-t-[2px] border-t-[#009DDF]"></div>
            </div>
          </div>
        </section>
        <section
          className="w-100 overflow-hidden pad-alt mb-[104px]"
          id="search-area"
        >
          <div className="w-full max-w-[791px] mx-auto pb-[20px]">
            <h4
              data-aos="fade-down"
              data-aos-delay="200"
              className="text-center text-[#1C1C1C] text-[32px] leading-[44px] font-semibold mb-[35px]"
            >
              Search Loan
            </h4>
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              className="w-full py-[48px] px-[35px] md:px-[67px] border border-[#EBEBEB] rounded-[20px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)]"
            >
              <h4 className="text-[#1C1C1C] text-xl font-semibold mb-[27px]">
                Make your search here
              </h4>
              <div className="input-ctn mb-[33px]">
                <input
                  type="text"
                  className={`w-full ${inputErr && "input-error"}`}
                  placeholder="BVN"
                  value={bvn}
                  onInput={(e: any) => setBvn(e?.target?.value)}
                />
                {inputErr && <p className="error-ctn">{inputErr}</p>}
              </div>
              <Button
                className="w-full mb-[33px] text-white p-[15px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
                disabled={inputErr || !bvn}
                onClick={submit}
                loading={loading}
              >
                Search
              </Button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
