import React from "react";
import analyst from "../../assets/images/data_analyst.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Spacer } from "../../styles";
import "./style.scss";
import { HeroCaption, HeroText } from "../../styles/styled";

const Debtsection = () => {
  React.useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section className="debt-hero-section  w-100 pad-alt">
        <div className="p-0 pt-5">
          <Spacer height="00px" heightSm="0px" />
          <div className=" position-relative ">
            <div className="row w-100 p-0 m-0 position-relative">
              <div
                className="col-md-6 mt-5 p-0 md:pr-3"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <h3 className="">
                  <span className="blue">Debt Collection </span>
                  that works and <span className="yellow">
                    Data insight
                  </span>{" "}
                  <br />
                  for better credit decision
                </h3>

                <Spacer height={"50px"} heightSm={"20px"} />
              </div>
              <div
                className="col-md-6 p-0 analyst-png mt-5"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  src={analyst}
                  className="img-fluid img-banner"
                  alt="banner"
                />
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Debtsection;
