import React from "react";
import { Spacer } from "../../styles";
import { HeroCaption } from "../../styles/styled";
import horizon from "../../assets/images/horizon.png";
import standard from "../../assets/images/standard.png";
import enterprise from "../../assets/images/enterprise.png";
import "./style.scss";
import Aos from "aos";
import "aos/dist/aos.css";

const Packages = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <section className="packages-hero-section w-100 pad-alt ">
      <Spacer height={"70px"} heightSm={"70px"} />

      <div className="row ">
        <div
          className="col-md-12 text-center flex flex-col items-center"
          data-aos="fade-up"
        >
          <HeroCaption className="fs-1">Our Packages</HeroCaption>
          <div className="horizon-img">
            <span>
              <img src={horizon} alt="packages" />
            </span>
          </div>
        </div>
      </div>
      <Spacer height={"70px"} heightSm={"20px"} />

      <div className="pt-5 mt-5">
        <div className="row overflow-x-hidden pad-alt justify-content-center d-flex image-content mb-5">
          <div className="col-md-6" data-aos="fade-right">
            <div className="standard">
              <div className="text-center relative">
                <img src={standard} alt="png" />
                <div className="btn-ctn absolute w-[95%] h-[30%] bottom-0 right-0 grid place-items-center">
                  <a
                    href="https://app.restructure.com.ng/createaccount/"
                    className="block w-full max-w-[175px]"
                  >
                    <button className="p-[11px] w-full text-[#009DDF] bg-white rounded-[5.52475px] text-base font-medium">
                      Sign up
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <div className="enterprise">
              <div className="text-center relative w-full">
                {" "}
                <img src={enterprise} alt="png" />
                <div className="btn-ctn absolute w-[95%] h-[30%] bottom-0 right-0 grid place-items-center">
                  <a
                    href="https://app.restructure.com.ng/createaccount/"
                    className="block w-full max-w-[175px]"
                  >
                    <button className="p-[11px] w-full text-[#009DDF] bg-white rounded-[5.52475px] text-base font-medium">
                      Sign up
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Packages;
