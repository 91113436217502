import Aos from "aos";
import agents_dash from "../../assets/svgs/agents_dash.svg";
import agents_dash_2 from "../../assets/svgs/agents_dash_2.svg";
import agent_call_image from "../../assets/svgs/agent_call_image.svg";
import Spacer from "../../components/Spacer";
import { useEffect } from "react";
import tick from "../../assets/svgs/tick.svg";
import agent_country_graphic from "../../assets/svgs/agent_country_graphic.svg";
import your_home_graphic from "../../assets/svgs/your_home_graphic.svg";

export default function Agents() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <div>
      <section className="hero flex flex-wrap lg:flex-nowrap gap-6 w-100 overflow-hidden pad-alt pr-0 mt-[136px]">
        <div
          className="left w-full lg:w-[calc(50%-12px)] pr-[1.5rem] sm:pr-[3rem] md:pr-[6rem] lg:p-0"
          data-aos="fade-right"
        >
          <h1
            className="font-boldAxiforma text-[45px] leading-[59px] text-dark font-extrabold"
            data-aos="fade-up"
          >
            Become a <span className="text-primary font-manrope">Remote</span>{" "}
            Restructure Call Agent today.{" "}
            <span className="text-accent">Earn a commission</span> on every
            amount recovered.
          </h1>
          <Spacer size="57px" />
          <a
            href="https://agents.restructure.com.ng/signup"
            className="block w-full max-w-[273px]"
          >
            <button
              data-aos="fade-up"
              className="w-full text-white p-[19px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
            >
              Sign up to become an agent
            </button>
          </a>
          <Spacer size="127px" />
        </div>
        <div
          className="right h-auto min-h-[476.43px] relative w-full lg:w-[calc(50%-12px)] max-w-[671.41]"
          data-aos="fade-left"
        >
          <img
            src={agents_dash}
            alt="agent dash one"
            className="absolute top-0 right-0 w-full max-w-[350px] md:max-w-[430px]"
          />
          <img
            src={agents_dash_2}
            alt="agent dash two"
            className="z-10 absolute top-[calc(350px/3*1.3)] right-[calc(350px/3*1.5)] md:top-[calc(430px/3*1.3)] md:right-[calc(430px/3*1.5)] w-full max-w-[350px] md:max-w-[430px]"
          />
        </div>
      </section>
      <Spacer size="81px" />
      <section className="hero flex flex-wrap lg:flex-nowrap gap-6 w-100 overflow-hidden pad-alt pl-0 bg-[#F9FDFE]">
        <div className="grid md:grid-cols-2">
          <div data-aos="fade-right">
            <img
              src={agent_call_image}
              alt="agent on call"
              className="w-full max-w-[1036px]"
            />
          </div>
          <div className="right pl-[1.5rem] sm:pl-[3rem] md:p-0">
            <Spacer size="111px" />
            <h3
              className="text-[40px] font-extrabold leading-[54.64px] text-dark"
              data-aos="fade-left"
            >
              Steps to becoming an agent
            </h3>
            <Spacer size="55px" />
            <ul className="options list-none p-0 grid gap-[28px] text-xl">
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>Sign up to become an Agent with Restructure.</p>
              </li>
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>Get Basic training from Restructure and be onboarded.</p>
              </li>
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>Get access to Agent Dashboard with Inbuilt call system.</p>
              </li>
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>Call and engage all categories of borrowers.</p>
              </li>
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>
                  Get them to enroll and make their payment on the Restructure
                  platform.
                </p>
              </li>
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>Track your activities on the Dashboard.</p>
              </li>
              <li className="flex gap-[31px]" data-aos="fade-left">
                <img src={tick} alt="tick" className="w-full max-w-[30px]" />
                <p>Get your commission on every loan you recover.</p>
              </li>
            </ul>
            <Spacer size="92px" />
          </div>
        </div>
      </section>
      <Spacer size="160px" />
      <section className="hero flex flex-wrap lg:flex-nowrap gap-6 w-100 overflow-hidden pad-alt">
        <div className="grid md:grid-cols-2 gap-[36px]">
          <div data-aos="fade-right" className="pl-[27px]">
            <h1
              className="font-boldAxiforma text-[48px] leading-[74px] text-dark font-semibold"
              data-aos="fade-up"
            >
              Work from{" "}
              <span className="text-primary font-manrope">anywhere</span> around
              the world right from the comfort of{" "}
              <span className="inline-block relative overflow-visible">
                your home{" "}
                <img
                  src={your_home_graphic}
                  alt="your home"
                  className="absolute -top-1.5 -left-5 min-w-[120%]"
                />
              </span>
            </h1>
            <Spacer size="73px" />
            <a
              href="https://agents.restructure.com.ng/signup"
              className="block w-full max-w-[273px]"
            >
              <button
                data-aos="fade-up"
                className="w-full text-white p-[19px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
              >
                Sign up to become an agent
              </button>
            </a>
          </div>
          <div data-aos="fade-left">
            <img
              src={agent_country_graphic}
              alt="agent countries"
              className="w-full max-w-[558px]"
            />
          </div>
        </div>
      </section>
    </div>
  );
}