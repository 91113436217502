import { useState } from "react";
import Aibanner from "../../sections/A1banner/Aibanner";
import Debtsection from "../../sections/debtsection/debtsection";
import Productsection from "../../sections/productsection/productsection";
import Solutionstepsection from "../../sections/solutionstepsection/solutionstepsection";
import React from "react";
import Aos from "aos";


const SolutionPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("spiritBtn")?.click();
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <>
      <section>
        <Debtsection />
        <Solutionstepsection />
        <Productsection />
        <Aibanner />
      </section>
    </>
  );
};

export default SolutionPage;
