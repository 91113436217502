import Aos from "aos";
import { useContext, useEffect, useRef, useState } from "react";
import validator from "validator";
import { NotificationContext } from "../../../context/notification-context";
import { useLocation, useNavigate } from "react-router";
import { multipartPostRequest } from "../../../utils/api";
import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { getFormattedNumber, nairaSymbol } from "../../../utils/functions";
import { Link } from "react-router-dom";
import empty_icon from "../../../assets/svgs/empty_icon.svg";

export default function SearchResult() {
  const input_1: any = useRef(null);
  const input_2: any = useRef(null);
  const input_3: any = useRef(null);
  const input_4: any = useRef(null);
  const input_5: any = useRef(null);
  const input_6: any = useRef(null);

  const state = useLocation().state;

  console.log(state);

  const [confirmed, setConfirmed] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [code, setCode] = useState("");
  const [inputErr, setInputErr] = useState("");

  function handleInput() {
    let value =
      (input_1.current.value || "") +
      (input_2.current.value || "") +
      (input_3.current.value || "") +
      (input_4.current.value || "") +
      (input_5.current.value || "") +
      (input_6.current.value || "");
    setCode(value);
  }

  const effect_count = useRef(0);

  useEffect(() => {
    if (effect_count.current > 0) {
      if (validator.isEmpty(code)) {
        setInputErr("Field is required");
      } else if (!validator.isNumeric(code)) {
        setInputErr("Only number are allowed here");
      } else if (!validator.isLength(code, { max: 6, min: 6 })) {
        setInputErr("Code must be exactly 6 digits");
      } else {
        setInputErr("");
        if (code) {
          confirm();
        }
      }
    }
    return () => {
      effect_count.current++;
    };
  }, [code]);

  const { showErrorMessage, showSuccessMessage } =
    useContext(NotificationContext);
  const navigate = useNavigate();

  const [user, setUser] = useState<any>({ id: state?.user?.id });
  const [restructure, setRestructure] = useState<any[]>([]);
  const [blacklist, setBlacklist] = useState<any[]>([]);

  async function confirm() {
    setConfirming(true);
    try {
      let form = new FormData();
      form.append("id", user.id);
      form.append("code", code);
      const {
        data: { status, message, data },
      } = await multipartPostRequest("/confirm_verification_code", form);

      if (status == "success") {
        let user = data?.info?.user;
        showSuccessMessage(message);
        setCode("");
        setUser((prev: any) => ({
          ...prev,
          first_name: user?.first_name,
          last_name: user?.last_name,
          middle_name: user?.middle_name,
          phone: user?.phone,
          photo: user?.photo,
        }));
        setRestructure(user?.restructure || []);
        setBlacklist(user?.blacklist || []);
        setConfirmed(true);
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message || "");
    }
    setConfirming(false);
  }

  useEffect(() => {
    Aos.init({
      duration: 1500,
      mirror: true,
      once: false,
      offset: -300,
      delay: 300,
    });
  }, []);
  return (
    <div>
      <div className="relative">
        {!confirmed ? (
          <section className="w-100 overflow-hidden pad-alt mt-[142px] mb-[104px]">
            <div className="w-full max-w-[791px] mx-auto pb-[20px]">
              <div
                data-aos="fade-up"
                data-aos-delay="200"
                className="w-full py-[66px] px-[35px] md:px-[67px] border border-[#EBEBEB] rounded-[20px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)]"
              >
                <h4 className="text-[#1C1C1C] text-center text-xl font-semibold mb-[66px] w-full max-w-[469px] mx-auto">
                  Enter the 6 digits code sent to the phone number that your BVN
                  is linked to to verify that it's yours
                </h4>
                {confirming ? (
                  <div className="w-full grid place-items-center py-[10px]">
                    <Icon
                      icon="mdi:loading"
                      className="text-3xl animate-spin"
                    />
                  </div>
                ) : (
                  <div className="grid grid-cols-6 gap-4 justify-center w-full max-w-[461px] mx-auto mb-[33px]">
                    <div className="input-ctn">
                      <input
                        type="password"
                        className="w-full rounded-[11px] text-center px-0 sm:px-[13px]"
                        autoFocus
                        maxLength={1}
                        placeholder="0"
                        ref={input_1}
                        onInput={(e: any) =>
                          e?.target?.value
                            ? (input_2?.current?.focus(), handleInput())
                            : handleInput()
                        }
                      />
                    </div>
                    <div className="input-ctn">
                      <input
                        type="password"
                        className="w-full rounded-[11px] text-center px-0 sm:px-[13px]"
                        maxLength={1}
                        placeholder="0"
                        ref={input_2}
                        onInput={(e: any) =>
                          e?.target?.value
                            ? (input_3?.current?.focus(), handleInput())
                            : (input_1?.current?.focus(), handleInput())
                        }
                      />
                    </div>
                    <div className="input-ctn">
                      <input
                        type="password"
                        className="w-full rounded-[11px] text-center px-0 sm:px-[13px]"
                        maxLength={1}
                        placeholder="0"
                        ref={input_3}
                        onInput={(e: any) =>
                          e?.target?.value
                            ? (input_4?.current?.focus(), handleInput())
                            : (input_2?.current?.focus(), handleInput())
                        }
                      />
                    </div>
                    <div className="input-ctn">
                      <input
                        type="password"
                        className="w-full rounded-[11px] text-center px-0 sm:px-[13px]"
                        maxLength={1}
                        placeholder="0"
                        ref={input_4}
                        onInput={(e: any) =>
                          e?.target?.value
                            ? (input_5?.current?.focus(), handleInput())
                            : (input_3?.current?.focus(), handleInput())
                        }
                      />
                    </div>
                    <div className="input-ctn">
                      <input
                        type="password"
                        className="w-full rounded-[11px] text-center px-0 sm:px-[13px]"
                        maxLength={1}
                        placeholder="0"
                        ref={input_5}
                        onInput={(e: any) =>
                          e?.target?.value
                            ? (input_6?.current?.focus(), handleInput())
                            : (input_4?.current?.focus(), handleInput())
                        }
                      />
                    </div>
                    <div className="input-ctn">
                      <input
                        type="password"
                        className="w-full rounded-[11px] text-center flex items-center px-0 sm:px-[13px]"
                        maxLength={1}
                        placeholder="0"
                        ref={input_6}
                        onInput={(e: any) =>
                          e?.target?.value
                            ? handleInput()
                            : (input_5?.current?.focus(), handleInput())
                        }
                      />
                    </div>
                    <div className="w-full input-ctn col-span-6">
                      {inputErr && <p className="error-ctn">{inputErr}</p>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        ) : (
          <section className="w-100 overflow-hidden pad-alt mt-[142px] mb-[104px]">
            <div className="w-full max-w-[1126px] mx-auto pb-[20px]">
              <h4
                data-aos="fade-down"
                data-aos-delay="200"
                className="text-[#1C1C1C] text-[32px] leading-[44px] font-semibold mb-[35px]"
              >
                Search Result
              </h4>
              <div
                data-aos="fade-up"
                data-aos-delay="200"
                className="w-full border border-[#EBEBEB] rounded-[20px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)]"
              >
                <div className="header border-b border-[#ECECEC] px-[35px] md:px-[67px] py-[31px] flex gap-[15px] items-center">
                  <div className="w-[55px] min-w-[55px] h-[55px] overflow-hidden uppercase rounded-[8px] bg-primary-200 text-primary font-bold text-3xl grid place-items-center">
                    {user?.photo ? (
                      <img
                        src={
                          "data:image/jpeg;charset=utf-8;base64," + user?.photo
                        }
                        alt="profile image"
                        className="w-full h-full relative -top-[10px]"
                      />
                    ) : (
                      user?.first_name?.charAt(0)
                    )}
                  </div>
                  <div>
                    <h4 className="mb-[2px] text-dark-700 text-xl font-semibold capitalize">
                      {`${
                        user?.first_name?.toLowerCase()
                          ? user?.first_name?.toLowerCase() + " "
                          : ""
                      }${
                        user?.middle_name?.toLowerCase()
                          ? user?.middle_name?.toLowerCase() + " "
                          : ""
                      }${
                        user?.last_name?.toLowerCase()
                          ? user?.last_name?.toLowerCase()
                          : ""
                      }`}
                    </h4>
                    <p className="text-dark-600 text-sm">
                      {user?.phone?.substring(0, user?.phone.length - 6) +
                        "******"}
                    </p>
                  </div>
                </div>
                <div className="content px-[35px] md:px-[67px] py-[31px]">
                  {[...restructure, ...blacklist].length > 0 ? (
                    <div className="table-ctn w-full overflow-x-auto">
                      <table className="w-full min-w-[550px] mb-[33px]">
                        <thead className="font-semibold text-lg text-dark-700">
                          <tr className="whitespace-nowrap">
                            <th className="py-[20px]">Name of Lender</th>
                            <th className="px-2">Loan Date</th>
                            <th className="px-2">Loan Amount</th>
                            <th className="px-2">Loan Interest</th>
                            <th className="px-2">Total Debt</th>
                            <th className="max-w-[198px]">Action</th>
                          </tr>
                        </thead>
                        <tbody className="font-medium text-base text-dark-700">
                          {restructure.map((item) => (
                            <tr key={item?.id}>
                              <td className="py-[20px]">
                                {item?.company_name}
                              </td>
                              <td className="px-2">
                                {item?.loan_date &&
                                  format(
                                    new Date(item?.loan_date),
                                    "MMM d, yyyy"
                                  )}
                              </td>
                              <td className="px-2">
                                {nairaSymbol}
                                {getFormattedNumber(
                                  parseFloat(
                                    item?.loan_amount?.toString() || "0"
                                  ),
                                  2
                                )}
                              </td>
                              <td className="px-2">
                                {nairaSymbol}
                                {getFormattedNumber(
                                  parseFloat(
                                    item?.loan_interest?.toString() || "0"
                                  ),
                                  2
                                )}
                              </td>
                              <td className="px-2">
                                {nairaSymbol}
                                {getFormattedNumber(
                                  parseFloat(
                                    item?.total_debt?.toString() || "0"
                                  ),
                                  2
                                )}
                              </td>
                              <td>
                                <a
                                  href={`https://users.restructure.com.ng/user_engage?id=${item?.restructure_id}&c=4`}
                                  className="w-full max-w-[198px] block"
                                >
                                  <button className="w-full whitespace-nowrap text-white p-[15px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]">
                                    View restructure options
                                  </button>
                                </a>
                              </td>
                            </tr>
                          ))}
                          {blacklist.map((item) => (
                            <tr key={item?.id}>
                              <td className="py-[20px]">
                                {item?.company_name}
                              </td>
                              <td className="px-2">{item?.loan_date}</td>
                              <td className="px-2">
                                {nairaSymbol}
                                {getFormattedNumber(
                                  parseFloat(
                                    item?.loan_amount?.toString() || "0"
                                  ),
                                  2
                                )}
                              </td>
                              <td className="px-2">
                                {nairaSymbol}
                                {getFormattedNumber(
                                  parseFloat(
                                    item?.loan_interest?.toString() || "0"
                                  ),
                                  2
                                )}
                              </td>
                              <td className="px-2">
                                {nairaSymbol}
                                {getFormattedNumber(
                                  parseFloat(
                                    item?.total_debt?.toString() || "0"
                                  ),
                                  2
                                )}
                              </td>
                              <td>
                                <Link
                                  to={`/new-loan/${user?.id}`}
                                  className="w-full max-w-[198px] block"
                                  state={{ loan: item }}
                                >
                                  <button className="w-full max-w-[198px] whitespace-nowrap text-[#009DDF] p-[13px] text-sm font-bold font-manrope rounded-[10px] bg-transparent border border-[#009DDF]">
                                    Suggest plan
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="py-[80px] md:py-[120px]">
                      <img
                        src={empty_icon}
                        alt="empty icon"
                        className="mx-auto mb-[30px] md:mb-[35px]"
                      />
                      <p className="w-full max-w-[506px] mx-auto text-center text-xl text-dark-700 font-medium">
                        Unfortunately we couldn’t find your loan in our
                        database. Kindly{" "}
                        <Link
                          to={`/new-loan/${user?.id}`}
                          className="font-semibold text-primary"
                        >
                          click here
                        </Link>{" "}
                        to give us the details of your loan.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
