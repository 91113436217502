import { useContext, useEffect, useRef, useState } from "react";
import { NewLoanContext } from "..";
import Button from "../../../components/Button";
import { useLocation } from "react-router";

export default function LoanDetailsForm() {
  const state = useLocation().state;
  const { formData, formErrors, handleInput, step, setStep } =
    useContext(NewLoanContext);
  const [isValid, setIsValid] = useState(false);

  const first_run = useRef(0);

  useEffect(() => {
    if (first_run.current > 0) {
      if (
        !formErrors.lender_name &&
        !formErrors.loan_amount &&
        !formErrors.lender_email &&
        formData.lender_name &&
        formData.loan_amount
      ) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }

    return () => {
      first_run.current++;
    };
  }, [formData, formErrors]);

  return (
    <section className="w-100 overflow-hidden pad-alt mt-[142px] mb-[104px]">
      <div className="w-full max-w-[791px] mx-auto pb-[20px]">
        <h4
          data-aos="fade-down"
          data-aos-delay="200"
          className="text-[#1C1C1C] text-[32px] leading-[44px] font-semibold mb-[35px]"
        >
          Kindly give us details of your loan
        </h4>
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="w-full py-[48px] px-[35px] md:px-[67px] border border-[#EBEBEB] rounded-[20px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)]"
        >
          <div className="form-area grid md:grid-cols-2 gap-x-[15px] gap-y-[32px] mb-[71px]">
            <div className="input-ctn">
              <p className="text-[10px] text-end text-primary">&nbsp;</p>
              <input
                type="text"
                placeholder="Name of Lender"
                className={`w-full ${formErrors?.lender_name && "input-error"}`}
                name="lender_name"
                id="lender_name"
                value={formData.lender_name}
                onInput={handleInput}
                disabled={state?.loan?.company_name}
              />
              {formErrors?.lender_name && (
                <p className="error-ctn">{formErrors?.lender_name}</p>
              )}
            </div>
            <div className="input-ctn">
              <p className="text-[10px] text-end text-primary">&nbsp;</p>
              <input
                type="text"
                placeholder="Outstanding Loan Amount"
                className={`w-full ${
                  formErrors?.loan_amount && "input-error"
                }`}
                name="loan_amount"
                id="loan_amount"
                value={formData.loan_amount}
                onInput={handleInput}
                disabled={state?.loan?.total_debt}
              />
              {formErrors?.loan_amount && (
                <p className="error-ctn">
                  {formErrors?.loan_amount}
                </p>
              )}
            </div>
            <div className="input-ctn">
              <p className="text-[10px] text-end text-primary">Optional</p>
              <input
                type="text"
                placeholder="Lender's phone number"
                className={`w-full ${
                  formErrors?.lender_phone && "input-error"
                }`}
                name="lender_phone"
                id="lender_phone"
                value={formData.lender_phone}
                onInput={handleInput}
              />
              {formErrors?.lender_phone && (
                <p className="error-ctn">{formErrors?.lender_phone}</p>
              )}
            </div>
            <div className="input-ctn">
              <p className="text-[10px] text-end text-primary">Optional</p>
              <input
                type="text"
                placeholder="Lender's email"
                className={`w-full ${
                  formErrors?.lender_email && "input-error"
                }`}
                name="lender_email"
                id="lender_email"
                value={formData.lender_email}
                onInput={handleInput}
              />
              {formErrors?.lender_email && (
                <p className="error-ctn">{formErrors?.lender_email}</p>
              )}
            </div>
          </div>
          <Button
            className="w-full mb-[33px] text-white p-[15px] text-sm font-bold font-manrope rounded-[10px] bg-[#009DDF]"
            disabled={!isValid}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </section>
  );
}
