import { Spacer } from "../../styles";
import "./styles.scss";
export default function ServiceLevelAgreement() {
  const table = [
    { title: "Definitions & Interpretations", page: "3" },
    { title: "Terms of Agreement", page: "6" },
    { title: "Duties and Responsibilities of Member", page: "7" },
    { title: "Duties and Responsibilities of Restructure", page: "9" },
    { title: "Credit Information Reports", page: "10" },
    { title: "Right", page: "11" },
    { title: "Service Charges", page: "11" },
    { title: "Indemnity", page: "12" },
    { title: "Limitation of Liability", page: "12" },
    { title: "Confidentiality", page: "13" },
    { title: "Miscellaneous", page: "14" },
    { title: "Jurisdiction & Arbitration", page: "16" },
  ];
  return (
    <div className="sla-page">
      <Spacer height="80px" heightSm="50px" />
      <section className="pad-alt">
        <h1>Service Level Agreement</h1>
        <div>
          <h6 className="text-center mb-3">TABLE OF CONTENTS</h6>
          <div className="mb-3 table-of-content">
            {table.map((item, idx) => (
              <div className="flex flex-nowrap font-semibold" key={idx}>
                <div className="num">{idx + 1}.</div>
                <a
                  href={"#item-" + (idx + 1)}
                  className="content whitespace-nowrap ml-3 mr-1 no-underline"
                >
                  {item.title}
                </a>
                <div className="w-full border-0 border-b border-dashed relative -top-1"></div>
                <div className="num mr-2 ml-1">{item.page}</div>
              </div>
            ))}
          </div>
          <p>
            <b>THIS DATA SERVICE LEVEL AGREEMENT</b> (“the <b>Agreement</b>”) is
            made this ___ day of ___________ 20___ (“the <b>Effective Date</b>”)
          </p>
          <p>
            <b>BETWEEN</b>
          </p>
          <p>
            <b>RESTRUCTURE</b>, a product of <b>BLACKLIST</b>, a private limited
            liability company duly incorporated in Nigeria and having its
            registered address at 8 Providence Street, Lekki Phase 1, Lagos
            State (hereinafter referred to as “<b>RESTRUCTURE</b>” which
            expression shall, where the context admits, include its
            successors-in-title and assigns)
          </p>
          <p>
            <b>AND</b>
          </p>
          <p>
            [Insert company name], a private limited liability company duly
            incorporated in Nigeria and having its registered address at [Insert
            address of Member] (hereinafter referred to as “<b>Member</b>” which
            expression shall, where the context admits, include its
            successors-in-title and assigns).
          </p>
          <p>
            Restructure and Member may sometimes in this Agreement where the
            context admits be individually referred to as a “<b>Party</b>” and
            collectively as “<b>Parties</b>”.
          </p>
          <p>
            <b>WHEREAS:</b>
          </p>
          <p className="flex gap-3">
            <span>(a)</span>
            <span>
              Restructure is a product of Blacklist, providing access to credit
              information and infrastructure, as well as supporting loan
              recovery for registered Members.
            </span>
          </p>
          <p className="flex gap-3">
            <span>(b)</span>
            <span>
              Member is a financial service provider [or insert brief
              description of Member] and is desirous of procuring Credit
              Information (as defined below) from Blacklist from time to time.
            </span>
          </p>
          <p className="flex gap-3">
            <span className="cs">(c )</span>
            <span>
              Parties have agreed that the terms hereof shall regulate Member’s
              access to the Services provided by Blacklist.
            </span>
          </p>
          <p className="flex gap-3">
            <span>(d)</span>
            <span>
              Parties understand that Restructure is not a credit bureau, and
              that Restructure enables sharing of information between Members as
              well as enables access to external Credit Information Databases.
            </span>
          </p>
          <p>
            <b>NOW THEREFORE, </b>in consideration of the mutual covenants and
            promises hereinafter set forth, the Parties hereto agree as follows:
          </p>
          <h6 className="flex gap-5 font-bold" id="item-1">
            <span>1.</span>
            <span>DEFINITIONS & INTERPRETATIONS</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">1.1</span>
            <div>
              <p>
                <b>Definitions</b>: In this Service Agreement, the following
                terms shall have the following meanings unless the context or
                meaning otherwise requires:
              </p>
              <p>
                <b>Agreement</b> means this Service Agreement and such
                amendments thereto as Restructure and the Member may from time
                to time agree in writing.
              </p>
              <p>
                <b>Business</b> Days means days on which banks in Nigeria are
                open for business excluding Saturdays, Sundays and Public
                Holidays.
              </p>
              <p>
                <b>Member</b> means organizations signed up to use Restructure
                Services.
              </p>
              <p>
                <b>Confidential</b> Information means any non-public information
                belonging to a Party including but not limited to information
                relating to the Services or to this Agreement, including the
                Technical Requirements, technical processes, identification
                procedures and all technology and procedures relating to the
                provision of the Services; the presentation, content,
                organization, details or format of Reports; procedures,
                guidelines and all other information or guidance as to data
                handling or statutory compliance; the organization, procedures,
                structure, financial details and other matters relating to the
                business affairs of that Party provided to the other Party.
              </p>
              <p>
                <b>Credit Subject/Subject</b> includes a Person who has applied
                for, obtained approval for, been granted or seeks to be granted
                financial assistance by a credit granting entity or has
                subscribed to or obtained any services from a credit granting
                entity (which have or could result in the grant or the obtaining
                of financial assistance whether directly, indirectly or
                incidentally) in any form and by whatsoever name called, from
                the credit granting entity at any time, whether prior to or
                during the existence of this Agreement. Without prejudice to the
                generality of the above, the term “Credit Subject” or “Subject”
                shall include the following:
              </p>
              <p className="flex gap-3 mb-3">
                <span>i.</span>
                <span>A Customer;</span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>ii.</span>
                <span>
                  A Person seeking to establish a relationship with a lender,
                  landlord or an employer;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>iii.</span>
                <span>
                  A Person seeking/obtaining financial assistance from a credit
                  granting entity by way of loan(s), advance(s), hire purchase,
                  leasing finance, or any other form or manner of credit;
                </span>
              </p>
              <p className="flex gap-3">
                <span>iv.</span>
                <span>A Guarantor for a Subject.</span>
              </p>
              <p>
                <b>Credit Information</b> shall mean all information/data with
                respect to a Subject held with a credit granting entity,
                including without limitation the following:
              </p>
              <p className="flex gap-3 mb-3">
                <span>i.</span>
                <span>
                  Information on any financial assistance granted or to be
                  granted by a credit granting entity to the Subject including
                  details on the amount and nature of such financial assistance
                  loans and advances, credit/ charge card facilities, hire
                  purchase, leasing finance, or any other form of credit/
                  security taken (if any) by the credit granting entity for
                  securing such financial assistance;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>ii.</span>
                <span>
                  The history and structure of any financial transaction
                  (credit) entered into by a Subject;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>iii.</span>
                <span>
                  The antecedents of any of the Subjects or a class of Subjects
                  of a credit granting entity;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>iv.</span>
                <span>
                  Any information on the Subject which is incidental to the
                  above-mentioned information, identification information,
                  information contained in media or other reports featuring the
                  Subject, information on any disputes, litigation, arbitration
                  that the Subject may be involved in or affected by;
                </span>
              </p>
              <p className="flex gap-3">
                <span>v.</span>
                <span>
                  Any other information that may be stipulated by Restructure
                  from time to time.
                </span>
              </p>
              <p>
                <b>Credit Information</b> Reports shall mean and include any
                information or data, in relation to a Subject, furnished by
                Restructure to the Member, pursuant to this Agreement in such
                form as may be decided upon, by Restructure or required by law,
                from time to time.
              </p>
              <p>
                <b>Customer</b> shall mean any Person in respect of whom/which
                the Member seeks the Services pursuant to this Agreement.
              </p>
              <p>
                <b>Database</b> shall mean a database maintained by Restructure
                or Restructure Members which contains all or certain components
                of the Credit Information supplied by data providers.
              </p>
              <p>
                <b>Force Majeure Event</b> means any event due to any cause
                beyond the reasonable control of Restructure, including, without
                limitation, sabotage, fire, flood, explosion, acts of God, civil
                commotion, strikes or industrial action of any kind, riots,
                pandemic, epidemic, insurrection, war, acts of government,
                computer hacking, computer crashes, etc.
              </p>
              <p>
                <b>Person</b> includes any individual and/or artificial person
                or entity.
              </p>
              <p>
                <b>Reports</b> means information and reports provided by
                Restructure to
                <b>Members</b> to the Services in response to information
                request on a Subject, and shall include, without limitation, a
                Credit Information Report.
              </p>
              <p>
                <b>Services</b> means loan recovery and credit information
                services to be provided by Restructure including provision of
                Credit Information, credit analytics tools and any such services
                in accordance with the provision of this Agreement and/or any
                other additional products/services that Restructure may provide
                to the <b>Member</b>, from time to time.
              </p>
              <p>
                <b>Service</b> Charges shall mean the fees and charges payable
                by the
                <b>Member</b> to Restructure for the Services. The Service
                Charge shall be exclusive of VAT.
              </p>
              <p>
                <b>Technical Requirements</b> shall mean technical and systems
                specifications and other technical documentation specified from
                time to time by Restructure, including stipulations in respect
                of method of communication, computer hardware, systems,
                applications, configurations and software, technical processes,
                identification procedures, transmission set up, virus and
                security checks, style, presentation, content and other matters
                which are to be utilized/ adopted by the <b>Member</b>.
                Technical Requirements will also include requirements in respect
                of the form, mode and manner in which the Services may be
                utilized by the <b>Member</b>.
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">1.2</span>
            <div>
              <p>
                <b>Interpretation</b>: In this Agreement, unless otherwise
                stated:
              </p>
              <p className="flex gap-3 mb-3">
                <span>i.</span>
                <span>
                  Words in the singular shall include the plural and vice-versa;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>ii.</span>
                <span>
                  Words in the masculine shall include the feminine and
                  vice-versa;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>iii.</span>
                <span>
                  Words denoting persons shall include corporate bodies,
                  unincorporated associations and partnerships;
                </span>
              </p>
              <p className="flex gap-3">
                <span>iv.</span>
                <span>
                  Headings in this Agreement are inserted for convenience of
                  reference only and shall not affect the interpretation or
                  construction of the Agreement.
                </span>
              </p>
            </div>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-2">
            <span>2.</span>
            <span>TERMS OF AGREEMENT</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.1</span>
            <span>
              This Agreement shall commence on the execution date and shall
              subject to prior termination in accordance with the provisions
              hereof, subsist for a period of one (1) calendar year from the
              execution date. Upon the expiration of the initial term, this
              Agreement shall automatically be renewed for subsequent periods of
              one calendar year. All pre-set terms, conditions and amounts will
              be carried forward at each renewal. Either party may terminate
              this Agreement upon giving thirty (30) days prior written notice
              of its intention to do so or not to renew same upon the expiration
              of a term.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.2</span>
            <span>
              By completing the subscription process and executing this
              Agreement, Member expressly agrees to be bound by the terms and
              conditions of this Agreement.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.3</span>
            <span>
              From the commencement of this Agreement, Restructure shall render
              the Services to the Member in accordance with the provisions of
              this Agreement.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.4</span>
            <span>
              Each Party shall have the right to include the other Party’s
              trademarks or logos in its advertising or promotional literature
              and web services, free of charge, provided that the other Party is
              notified and appropriate approval obtained (which shall not be
              unreasonably withheld or delayed), prior to such inclusion.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.5</span>
            <span>
              All Credit Information furnished to the Member by Restructure
              hereunder is for the exclusive internal use of the Member to
              assist, along with the other information and tools that the Member
              may have, in taking decisions relating to its businesses as they
              relate to the permissible purpose. Member shall not use such
              information for any unlawful activity or purpose nor shall it
              resell or supply the information to any other person(s) or
              businesses.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.6</span>
            <span>
              Where the Member is granted an electronic interface/portal which
              allows it to query the Database directly and to generate or
              download Reports, the Member acknowledges and understands that the
              information generated thereby represents information about likely
              matches to the search criteria determined solely by the Member.
              Accordingly, Member shall be solely responsible for the
              information so generated and the content of the Report(s)
              downloaded.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.7</span>
            <span>
              Member acknowledges that Restructure does not, and cannot
              guarantee or warrant the correctness, completeness, accuracy,
              merchantability or fitness of the information in a Credit
              Information Report or any other Report for a particular purpose,
              as such information usually is not the product of an independent
              investigation by Restructure. Member also acknowledges and agrees
              that there is a possibility that such information will contain
              errors and/or, omissions because of many factors including
              negligence of the third parties providing them to Restructure. The
              Member undertakes that it shall therefore rely on the information
              at its sole risk and that Restructure shall not be liable to it
              for any loss or injury arising out of or caused, in whole or in
              part from the Member’s use of the Credit Information, except for
              acts of negligence by Restructure in compiling, collecting,
              reporting, communicating or delivering the information.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.8</span>
            <span>
              Members agrees that data for recovery campaign will be compiled
              and Uploaded on a Comma-separated values (Csv) format.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.9</span>
            <span>
              Members acknowledges that Restructure will own the exclusive
              rights to the Non-performing loan (NPL) portfolio used for
              recovery campaigns on our platform within the agreed life time.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">2.10</span>
            <span>
              Subject to the provisions herein and to any rights or obligations,
              which have accrued prior to termination, neither party shall have
              any further obligation to the other under this Agreement upon the
              termination thereof. Termination of this Agreement howsoever
              caused shall be without prejudice to any right or liabilities of a
              party that may have accrued at the date of termination.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-3">
            <span>3.</span>
            <span>DUTIES AND RESPONSIBILITIES OF MEMBER</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">3.1</span>
            <span>
              The Member shall obtain documented consent of a Credit Subject
              prior to submitting obtaining his Credit Information from
              Restructure. The Member shall, whenever requested by Restructure
              to do so, provide Restructure within three (3) Business Days of
              such request, documentation and/or other proofs reasonably
              acceptable to Restructure as evidence of the Member’s entitlement
              to access particular Credit Information. Restructure is entitled
              to refuse or delay request for Credit Information until such
              requested documentation and/or other proof is provided by the
              Member.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">3.2</span>
            <span>
              The Member acknowledges that Credit Information or other
              information provided to it by Restructure pursuant to the
              provision of the Services is solely for its internal business
              requirements and that it shall at all times ensure that adequate
              measures and actions are taken to safeguard confidentiality of
              such information. The Member shall not transfer or disclose
              contents of any Report in whole or in part to any person who is
              not authorized to receive such information under this Agreement or
              under any applicable law, for any reason whatsoever. The Member
              shall ensure that all Reports are duly protected from any
              unauthorized access or disclosure, loss, modification or misuse of
              any kind. The Member covenants and irrevocably undertakes to take
              reasonable measures and actions in accordance with industry best
              practices in safeguarding the confidentiality of Reports obtained
              pursuant to the Services.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">3.3</span>
            <span>
              Member acknowledges and agrees that the Credit Information
              provided by Restructure pursuant to this Agreement is raw data
              which requires careful consideration and interpretation by the
              Member. Accordingly, the Member warrants that it has the skills
              and judgment to undertake such consideration and make such
              interpretation and its own credit decisioning rules which are not
              wholly dependent on the supplied Credit Information. Member also
              agrees and undertakes that Credit Information shall not be the
              sole basis of a commercial decision. Member agrees to carry out
              all the necessary due diligence and make all the enquiries that
              are ordinarily required of a prudent business manager before
              making the above-mentioned decision. The Member also agrees that
              Restructure shall not be liable for and undertakes to indemnify
              Restructure and hold Restructure fully and completely indemnified
              against, any actions, proceedings, claims, liabilities (including
              statutory liability), penalties, demands and costs (including,
              without limitation, legal costs of Restructure) awards, damages,
              losses and/or expenses, arising from, relating or attributable to
              its interpretation of the Credit Information and its decision
              whether or not to grant a credit facility to a Subject.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">3.4</span>
            <div>
              <p>
                The Member hereby represents, warrants and covenants to
                Restructure that:
              </p>
              <p className="flex gap-3 mb-3">
                <span>3.4.1</span>
                <span>
                  it has the authority to enter into this Agreement and to
                  perform its obligations hereunder
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>3.4.2</span>
                <span>
                  the execution and performance of this Agreement does not and
                  will not violate any agreement to which it is a party or by
                  which it is otherwise bound;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>3.4.3</span>
                <span>
                  when executed and delivered, this Agreement will constitute
                  its legal, valid and binding obligation, enforceable in
                  accordance with its terms; and
                </span>
              </p>
              <p className="flex gap-3">
                <span>3.4.4</span>
                <span>
                  it has the necessary skills and expertise to analyses and
                  interpret the Credit Information provided by Restructure with
                  a view to taking relevant decisions
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">3.5</span>
            <div>
              <p>
                Where Member shall be providing Restructure with Credit Data,
                Member undertakes to:
              </p>
              <p className="flex gap-3 mb-3">
                <span>3.5.1</span>
                <span>
                  supply relevant Credit Information (including updates,
                  modifications and changes) to Restructure in the form and
                  frequency stipulated from time to time by Restructure;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>3.5.2</span>
                <span>
                  ensure that the Credit Information it will supply to
                  Restructure is reliable, true, accurate and complete in all
                  respects and shall conform to the Technical Requirements.
                  Member shall be liable for any error or inaccuracy in any
                  Credit Information submitted to Restructure and shall make all
                  efforts to immediately correct and communicate same in a form
                  acceptable to Restructure;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>3.5.3</span>
                <span>
                  upon realization or notification of such error or inaccuracy,
                  fully assist Restructure in responding to any notice, claim,
                  or proceedings made by any person against Restructure in
                  respect of the Credit Information contributed by Member;
                </span>
              </p>
              <p className="flex gap-3">
                <span>3.5.4</span>
                <span>to comply with the Technical Requirements;</span>
              </p>
              <p className="flex gap-3">
                <span>3.5.4</span>
                <div>
                  <p>
                    indemnify Restructure and hold it harmless and keep it, at
                    all times, fully indemnified and held harmless from and
                    against all actions, proceedings, claims, liabilities
                    (including statutory liability), penalties, demands and
                    costs (including, without limitation, legal costs of
                    Restructure) awards, damages, losses and/or expenses,
                    however arising directly or indirectly as a result of:
                  </p>
                  <p className="flex gap-3 mb-3">
                    <span>(a)</span>
                    <span>
                      Any error, inaccuracy or misleading data or information
                      contained in the Credit Information furnished by the
                      Member;
                    </span>
                  </p>
                  <p className="flex gap-3 mb-3">
                    <span>(b)</span>
                    <span>
                      Any breach or non-performance by the Member of any of its
                      undertakings, warranties, covenants or obligations in
                      relation to the Credit Information or provision thereof;
                    </span>
                  </p>
                  <p className="flex gap-3">
                    <span className="cs">(c )</span>
                    <span>
                      Any claim or proceeding brought by any person against
                      Restructure, arising out of the Credit Information
                      provided by the Member to Restructure or the use by the
                      Member of the Services provided by Restructure.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-4">
            <span>4.</span>
            <span>DUTIES AND RESPONSIBILITIES OF RESTRUCTURE</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">4.1</span>
            <span>
              Restructure agrees to provide the Services to the Member based on
              the terms and conditions set out in this Agreement and in
              accordance with best practices.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">4.2</span>
            <span>
              Restructure shall, from time to time, develop operational
              guidelines, disciplinary and contractual procedures for
              appropriate sanctions to be applied in relation to improper use of
              access. Restructure shall provide the Member with not less than
              fifteen (15) days’ notice of the proposed guidelines and or
              procedure or proposed amendments thereto and shall give due
              consideration to the Member’s comments in respect thereof. Member
              shall be deemed to have accepted the proposed guideline or
              procedure or the amendment where it fails to provide Restructure
              with its comments thereon within the fifteen (15) day notice
              period.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">4.3</span>
            <div>
              <p>
                Restructure hereby represents, warrants and covenants to the
                Member that:
              </p>
              <p className="flex gap-3 mb-3">
                <span>4.3.1</span>
                <span>
                  it has the necessary experience, qualifications and expertise
                  to enable it to provide the Services in accordance with the
                  provisions of this Agreement;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>4.3.2</span>
                <span>
                  the Services will be performed by properly trained and
                  qualified personnel, in a professional and workmanlike manner
                  and in accordance with the provision of this Agreement and any
                  existing law governing the provision of the Services.
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>4.3.3</span>
                <span>
                  it has commercially reasonable backup and disaster recovery
                  procedures and facilities to provide the Services during the
                  continuation of this Agreement;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>4.3.4</span>
                <span>
                  the Services will be performed in accordance with the
                  applicable specifications therefore and will not violate or
                  infringe the intellectual, copyright, patent or other rights
                  of any third party; and
                </span>
              </p>
              <p className="flex gap-3">
                <span>4.3.5</span>
                <span>
                  it shall use reasonable efforts to accurately process and
                  incorporate on its platform information supplied to it by
                  Member and the information obtained from other credible
                  sources and to provide the Services in accordance with this
                  Agreement.
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">4.4</span>
            <div>
              <p>
                Save as set out above, Restructure does not make any other
                warranty whether express or implied by law or custom.
                Particularly, Restructure does not warrant:
              </p>
              <p className="flex gap-3 mb-3">
                <span>4.4.1</span>
                <span>
                  the accuracy, or completeness of any of the Credit Information
                  contained in its Database and/or of the Reports, as all Credit
                  Information on which the Reports would be based would be
                  supplied wholly by third parties to Restructure;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>4.4.2</span>
                <span>
                  that the access to the Service will be provided by Restructure
                  at all times or will be free from errors or that any
                  identified defect will be corrected; as such access is
                  dependent/based on imported software/technology which does not
                  warrant to be completely error free;
                </span>
              </p>
              <p className="flex gap-3">
                <span>4.4.3</span>
                <span>
                  that access to the Reports if provided by electronic means
                  will be provided free from any virus or other malicious,
                  destructive or corrupting code, program or macro as such
                  provision by electronic means involves multiple factors which
                  are outside the control of Restructure. However, Restructure
                  shall use technology to check virus in accordance with
                  industry standards from time to time.
                </span>
              </p>
            </div>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-5">
            <span>5.</span>
            <span>CREDIT INFORMATION REPORTS</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">5.1</span>
            <span>
              Credit Information Reports provided to the Member shall at all
              times be subject to this Agreement and all notes and disclaimers
              contained in such Credit Information Reports.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">5.2</span>
            <div>
              <p>
                Restructure reserves the right to refuse to provide or suspend
                provision of the Services for a period of time to the Member if
                Restructure is of the reasonable opinion that any of events
                listed below has occurred and (in case the said occurrence is
                capable of being remedied) the Member shall have failed to
                remedy same within fourteen (14) Business Days (or such longer
                period as Restructure may in its absolute discretion decide)
                after receipt of written notice giving full particulars of the
                occurrence and demanding it to be remedied within the said
                fourteen (14) Business Days (or such longer period):
              </p>
              <p className="flex gap-3 mb-3">
                <span>5.2.1</span>
                <span>
                  The Member is in breach of any of this Agreement; or
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>5.2.2</span>
                <span>
                  The Member proposes to use or has used the Reports for
                  purposes other than the permissible purposes; or
                </span>
              </p>
              <p className="flex gap-3">
                <span>5.2.3</span>
                <span>
                  The provision of the Services to the Member would be contrary
                  to applicable laws.
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">5.3</span>
            <span>
              Where provision of the Services has been suspended and the Member
              still has not remedied the default that led to the suspension of
              services after thirty (30) days of the suspension, Restructure
              shall be entitled to terminate the provision of the Services to
              the Member upon giving seven (7) Business Days’ notice of its
              intention to do so. Provided that where Restructure reasonable
              determines that the circumstances warrants same, such as where
              directed by relevant regulatory authority or a court of competent
              jurisdiction so to do, or where Restructure reasonably believes
              same necessary to avoid regulatory or legal action/sanction, it
              may shorten the notification process required for the termination
              of provision of Services.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">5.4</span>
            <span>
              Notwithstanding the provisions of clauses 5.2 and 5.3, Restructure
              may at its absolute discretion decide to terminate the provision
              of the Services at the expiration of the notice period in clause
              5.2 rather than suspend provision of Service.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-6">
            <span>6.</span>
            <span>RIGHTS</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">6.1</span>
            <span>
              All intellectual property rights in the Credit Information Report
              and or any other Reports issued by Restructure in the course of
              providing the Services shall vest in Restructure. Restructure
              hereby grants the Member upon payment of the relevant Service
              Charge, the perpetual exclusive and non-assignable license to use
              the Credit Information Report and or the Reports together with the
              information contained therein solely for its business purposes.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-7">
            <span>7.</span>
            <span>SERVICE CHARGES</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">7.1</span>
            <span>
              As consideration for Restructure providing the Services to the
              Member, the Member shall pay to Restructure the Service Charges as
              may from time to time be specified by Restructure and agreed with
              the Member. Restructure reserves the right at its absolute
              discretion to review the Service Charge and or the payment terms
              in respect thereof and undertakes to give the Member thirty (30)
              days prior written notice of its intention to revise the charges
              or terms. For the purpose of this Agreement, Service Charge shall
              be payments relating to the cost of each report.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">7.2</span>
            <span>
              Any errors in an invoice shall be communicated to Restructure
              within seven (7) days of receipt by a Member of such invoice,
              failing which the invoice shall be deemed to be correct as issued.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">7.3</span>
            <span>
              Restructure operates an electronic wallet payment system. “Wallet
              payments” refers to payments made with a digital, virtual wallet
              in which Member deposits a balance into their electronic wallet on
              Restructure platform, and use this to make payments for loan
              recovery campaigns and search reports. Unless Parties agree in
              writing otherwise, all payments due under this Agreement shall be
              made by Member from the virtual wallet on the Restructure portal
              to Restructure, in advance of loan recovery campaigns and searches
              conducted. Member shall also pay for any service or any other tax
              that may be levied on the Services.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-8">
            <span>8.</span>
            <span>INDEMNITY</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">8.1</span>
            <div>
              <p>
                Member hereby undertakes and agrees to indemnify Restructure and
                hold it harmless and keep it, at all times, fully indemnified
                and held harmless from and against all actions, proceedings,
                claims, liabilities (including statutory liability), penalties,
                demands and costs (including, without limitation, legal costs of
                Restructure ) awards, damages, losses and/or expenses, arising
                directly (to the exclusion of liability for indirect or
                consequential losses, damages, costs, charges, expenses or
                otherwise, loss of business opportunity, loss of revenue, profit
                or anticipated profit) as a result of:
              </p>
              <p className="flex gap-3 mb-3">
                <span>8.1.1</span>
                <span>
                  Its interpretation of the Credit Information and its decision
                  based on the said interpretation
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>8.1.2</span>
                <span>
                  Any contravention by the Member of, or failure to comply with,
                  any applicable laws, regulations, or rules in its use of the
                  Services and or a Report;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>8.1.3</span>
                <span>
                  Any breach or non-performance by the Member of any of its
                  undertakings, warranties, covenants or obligations under this
                  Agreement;
                </span>
              </p>
              <p className="flex gap-3">
                <span>8.1.4</span>
                <span>
                  Any interruption caused by a Member, which results in the
                  delay or inability of Restructure to perform its obligations
                  under this Agreement.
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">8.2</span>
            <span>
              Restructure shall indemnify Member and keep Member fully and
              effectively indemnified against any and all losses, claims,
              damages, costs, charges, expenses, liabilities, demands,
              proceedings and actions (to the exclusion of liability for
              indirect or consequential losses, damages, costs, charges,
              expenses or otherwise, loss of business opportunity, loss of
              revenue, profit or anticipated profit) which Member may sustain or
              incur as a result of any claim or action which may be brought or
              established against Member by any person due to Restructure ’s
              negligence in compiling, collecting, reporting, communicating or
              delivering Credit Information to the Member.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-9">
            <span>9.</span>
            <span>LIMITATION OF LIABILITY</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">9.1</span>
            <span>
              Subject to the provisions of this Agreement, neither Party shall
              be liable to the other, in contract, tort or otherwise, for any
              indirect or consequential losses, damages, costs, charges,
              expenses or otherwise, loss of business opportunity, loss of
              revenue, profit or anticipated profit which the Party suffers or
              incurs arising out of or in connection with the provision of or
              subscribing for the Services or use of the Reports or howsoever
              under or in connection with or incidental to this Agreement or the
              implementation thereof.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">9.2</span>
            <span>
              If, for any reason, paragraphs 2.7 and/or 3.3 above are found
              inoperative or declared by a court of competent jurisdiction, to
              be unenforceable or invalid and liability is imposed on
              Restructure, then Member agrees that Restructure ’s aggregate
              liability for any and all losses or injuries to the Member arising
              out of any acts or omissions of Restructure in connection with
              anything to be done or furnished hereunder, regardless of the
              cause of the loss or injury and regardless of the nature of the
              legal or equitable right claimed to have been violated, shall not
              exceed the aggregate amount paid by the Member for the Services
              for the preceding 12 calendar months or for such lesser period in
              which the Services have been rendered.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">9.3</span>
            <span>
              Member acknowledges that Restructure has set its prices and
              entered into this Agreement in reliance upon the limitations and
              exclusions of liability, the disclaimers of warranties and damages
              and the Member’s indemnity obligations set forth in this
              Agreement, and that the same form an essential basis of the
              bargain between the Parties.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">9.4</span>
            <span>
              The Parties agree that the limitations and exclusions of liability
              and disclaimers specified in this Agreement shall survive and
              apply even if this Agreement is found to have failed of their
              essential purpose.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-10">
            <span>10.</span>
            <span>CONFIDENTIALITY</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.1</span>
            <span>
              Each Party undertakes, at all times, during continuance of this
              Agreement and thereafter, to hold and keep secure and confidential
              the Confidential Information of the other Party and not to
              disclose or permit same to be disclosed to any person other than
              its Authorized Personnel, and to prevent the use thereof unless
              authorized by this Agreement or required by law.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.2</span>
            <span>
              All Confidential Information furnished to the Member by
              Restructure shall be held in confidence, shall not be copied, and
              shall not be revealed or made accessible in whole or in part to
              any other person, except where a Credit Subject consents to the
              disclosure of his Data or the disclosure is expressly permitted
              under this Agreement. The Member shall be entitled to reveal
              details of a Credit Subject’s Credit Information Report to that
              Credit Subject.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.3</span>
            <div>
              <p>
                A Party will not have any confidentiality obligations under this
                Agreement with respect to information which, through no wrongful
                act of it:
              </p>
              <p className="flex gap-3 mb-3">
                <span>10.3.1</span>
                <span>
                  A Party will not have any confidentiality obligations under
                  this Agreement with respect to information which, through no
                  wrongful act of it:
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>10.3.2</span>
                <span>
                  is independently developed (but only where this is without
                  reference to the other Party’s Confidential Information);
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>10.3.3</span>
                <span>
                  is lawfully obtained from a third party under no restriction
                  on use or disclosure and that Party is not aware that the
                  third party is breaching any obligation of confidentiality
                  relating to the information disclosed;
                </span>
              </p>
              <p className="flex gap-3 mb-3">
                <span>10.3.4</span>
                <span>
                  becomes publicly known through no action or negligence of the
                  other Party; or
                </span>
              </p>
              <p className="flex gap-3">
                <span>10.3.5</span>
                <span>
                  is required to be disclosed by law or by any other competent
                  regulatory authority. Provided that that Party shall
                  immediately notify the other Party of any request by a
                  competent regulatory authority that the Confidential
                  Information be disclosed and shall exercise all reasonable
                  endeavors to co-operate with the other Party in its effort to
                  protect its interest in relation to the disclosure of the
                  Confidential Information;
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.4</span>
            <span>
              Parties acknowledge that monetary damages may not be a sufficient
              remedy for unauthorized disclosure of any Confidential
              Information/materials and that either Party shall be entitled,
              without waiving any other rights or remedies, to such injunctive
              or equitable relief as may be deemed proper.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.5</span>
            <span>
              The Member may, upon a Customer’s request, disclose Credit
              Information to the Customer, if the law permits disclosure of such
              information.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.6</span>
            <span>
              10.6 The term "<b>Authorized Personnel</b>" shall mean those
              persons who are a part of the Member's organization and who
              require the Confidential Information to discharge their duty as a
              part of the Member's organization and whose possession of such
              Confidential Information will not give rise to a conflict of
              interest or to any misuse whatsoever. Member shall ensure that
              such Authorized Personnel shall be bound by confidentiality
              obligations similar to the foregoing obligations.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">10.7</span>
            <span>
              The confidentiality obligation imposed on Parties pursuant to this
              Clause 10 shall survive the termination of this Agreement.
            </span>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-11">
            <span>11.</span>
            <span>MISCELLANEOUS</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.1</span>
            <div>
              <p className="font-bold">Non-Assignment</p>
              <p>
                Neither this Agreement nor any interest therein may be assigned,
                in whole or in part, by either Party without the prior written
                consent of the other Party (such consent not to be unreasonably
                withheld) and any purported assignment made in violation of this
                Clause shall be void; provided, however, that each Party hereto
                shall have the right to assign this Agreement to any entity
                which, by way of: (i) merger, or (ii) consolidation, or (iii)
                the acquisition of substantially all of the entire business and
                assets of the assigning Party relating to the subject matter of
                this Agreement, succeeds to the interest of the assigning Party.
                Such consent shall not be unreasonably withheld, so long as such
                assignment does not materially affect the nature and the scope
                of the rights and benefits due to the non-assigning Party under
                the terms of this Agreement.
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.2</span>
            <div>
              <p className="font-bold">Notices</p>
              <p>
                All notices, demands or other communications required or
                permitted to be given or made in this Agreement shall be in
                writing and may be delivered by hand, registered mail, courier
                service, e-mail or facsimile to such other addresses and numbers
                as may be specified from time to time in writing by either
                Parties. Notices or communications given under this Agreement
                shall be deemed to be received by the recipient:
              </p>
              <p className="flex gap-3 mb-3">
                <span>(a)</span>
                <span>
                  In the case of hand delivery or registered mail or courier,
                  upon written acknowledgement of receipt by an agent or
                  representative of the receiving party;
                </span>
              </p>
              <p className="flex gap-3">
                <span>(b)</span>
                <span>
                  In the case of facsimile/e-mail, upon completion of
                  transmission as long as the sender's facsimile
                  machine/computer creates, and the sender retains a
                  transmission report showing successful transmission. Provided
                  that in case of the date of receipt not being a Business Day,
                  notice shall be deemed to have been received on the next
                  Business Day. Provided further that in case of a notice being
                  forwarded by facsimile/e-mail, a copy of the notice shall also
                  be forwarded by hand delivery, registered mail or courier
                  service.
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.3</span>
            <div>
              <p className="font-bold">Data Protection</p>
              <p>
                The Member shall, during the term of this Agreement, maintain
                adequate physical, technical and organisational measures to
                safeguard and ensure the protection and security of all personal
                data or Credit Information provided to it from loss, misuse,
                unauthorized access, alteration or unlawful destruction. The
                Member also undertakes to comply with the provisions of the
                Nigeria Data Protection Regulation, 2019 or any amendments
                thereto.
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.4</span>
            <div>
              <p className="font-bold">Force Majeure</p>
              <p>
                Neither Restructure nor the Member shall be liable for any
                failure to perform any of its obligations under this Agreement
                if the performance is prevented, hindered or delayed by a Force
                Majeure Event and in such case, its obligations shall be
                suspended for so long as the Force Majeure Event continues,
                provided that Force Majeure Event shall not be applicable to an
                obligation to pay Service Charges. The affected party shall
                promptly inform the other party of the existence of a Force
                Majeure Event and both parties shall consult together to find a
                mutually acceptable solution.
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.5</span>
            <div>
              <p className="font-bold">Variation</p>
              <p className="flex gap-3 mb-3">
                <span>(a)</span>
                <span>
                  Other than as otherwise provided in this Agreement, all
                  alterations to this Agreement shall be made by Restructure
                  sending a written notice to the Member informing it of such
                  variation.
                </span>
              </p>
              <p className="flex gap-3">
                <span>(b)</span>
                <span>
                  Restructure reserves the right to vary or modify the terms and
                  conditions contained herein. Any variation or modification to
                  these terms and conditions will be proposed to the Member who
                  is required to communicate its response to Restructure within
                  thirty (30) days of receipt of the new terms and conditions.
                  Member shall be deemed to have accepted the revised terms and
                  conditions, if it fails to notify Restructure of its
                  objections thereto within the aforementioned thirty (30) days
                  and or continues to access the Database, purchase Reports or
                  utilize any of the Services after the expiration of the thirty
                  (30) days’ notice of such modification.
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.6</span>
            <div>
              <p className="font-bold">Severability</p>
              <p>
                In the event any or more of the provisions contained in this
                Agreement shall for any reason be held to be invalid, illegal or
                unenforceable in any respect, such invalidity, illegality or
                unenforceability shall not affect any other provision of this
                Agreement but
              </p>
              <p className="flex gap-3 mb-3">
                <span>(a)</span>
                <span>
                  if permitted by applicable law, there shall be deemed
                  substituted for the provision at issue a valid, legal and
                  enforceable provision as similar as possible to the provision
                  at issue;
                </span>
              </p>
              <p className="flex gap-3">
                <span>(b)</span>
                <span>
                  if substitution is not permitted by applicable law, this
                  Agreement shall be construed as if such invalid, illegal or
                  unenforceable provision had never been set forth herein, and
                  this Agreement shall be carried out as nearly as possible
                  according to its original terms and intent.
                </span>
              </p>
            </div>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">11.7</span>
            <div>
              <p className="font-bold">Waiver</p>
              <p>
                A failure by any party hereto, to exercise or enforce any rights
                conferred upon it by this Agreement, shall not be deemed to be a
                waiver of any such rights or operate so as to bar the exercise
                or enforcement thereof at any subsequent time or times.
              </p>
            </div>
          </p>
          <h6 className="flex gap-5 font-bold" id="item-12">
            <span>12.</span>
            <span>ARBITRATION</span>
          </h6>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">12.1</span>
            <span>
              This Agreement shall be governed by and is subject to the laws of
              the Federal Republic of Nigeria.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">12.2</span>
            <span>
              In the event of any claim, dispute or difference (“Dispute”)
              arising out of or in relation to this Agreement, Parties shall
              endeavor to resolve the dispute themselves within fifteen (15)
              days from the date of notification of dispute. Failing settlement
              by Parties themselves, Parties shall mediate the dispute with the
              help of appointed professionals on their behalf within ten (10)
              days. Failing amicable settlement by the appointed professionals,
              the dispute shall be settled by a sole arbitrator in accordance
              with the provisions of the Arbitration and Conciliation Act, Cap
              A18, Laws of the Federation of Nigeria, 2004 and any modification
              or re-enactment thereof in force at the relevant time. Where
              parties fail to agree on a sole arbitrator within seven (7) days
              of the initial proposal of one, the sole arbitrator shall be
              appointed by the Chairperson of the Chartered Institute of
              Arbitrators UK (Nigeria Branch). All costs of such arbitration
              shall be borne by the Parties in equal proportion, unless
              otherwise determined by the arbitrator.
            </span>
          </p>
          <p className="flex gap-[1.1rem]">
            <span className="font-bold">12.3</span>
            <span>
              Any matters which require the intervention or any determination by
              a court, whether prior to, or during or after the aforesaid
              arbitration, shall be subject to the exclusive jurisdiction of the
              Courts in Nigeria.
            </span>
          </p>
          <p>
            <b>IN WITNESS WHEREOF,</b> the Parties have executed this Agreement
            by their duly authorized representatives the day and year first
            above written.
          </p>
          <div className="w-full grid md:grid-cols-2 gap-6">
            <div className="font-bold">
              <p className="mb-10">
                for and on behalf of: <br /> RESTRUCTURE
              </p>
              <p className="border-0 border-b border-solid w-full max-w-[400px] mb-5"></p>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Name:</p>
                <p className="border-0 border-b border-solid w-full max-w-[360px]"></p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Designation:</p>
                <p className="border-0 border-b border-solid w-full max-w-[322px]"></p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-12">
                <p>Date:</p>
                <p className="border-0 border-b border-solid w-full max-w-[365px]"></p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Witnessed By</p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Name:</p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Date:</p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Signature:</p>
              </div>
            </div>
            <div className="font-bold">
              <p className="mb-10">
                for and on behalf of: <br /> [Insert Company name]
              </p>
              <p className="border-0 border-b border-solid w-full max-w-[400px] mb-5"></p>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Name:</p>
                <p className="border-0 border-b border-solid w-full max-w-[360px]"></p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Designation:</p>
                <p className="border-0 border-b border-solid w-full max-w-[322px]"></p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-12">
                <p>Date:</p>
                <p className="border-0 border-b border-solid w-full max-w-[365px]"></p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Witnessed By</p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Name:</p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Date:</p>
              </div>
              <div className="flex gap-1 flex-nowrap mb-10">
                <p>Signature:</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacer height="80px" heightSm="50px" />
    </div>
  );
}
